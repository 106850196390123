import React, { useEffect, useState } from "react";
import { useFormik, FieldArray, FormikProvider, Form } from "formik";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { FormField } from "../../../common/FormFields";
import { getHeaderDropDownList } from "../../../../services/LayoutHeaderServices";
import HtmlInputEditor from "../../../common/HtmlInputEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGear,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import {
  addEventLandingPage,
  addUpcomingEventPage,
  getUpcomingEventDetails,
  getUpcomingEventListDropDown,
  updateEventLandingPage,
  updateUpcomingEventPage,
} from "../../../../services/EventService";
import {
  FORM_DATE_FORMAT,
  FORM_TIME_FORMAT,
} from "../../../../utils/Constants";
import moment from "moment";
import PermissionsGate from "../../../../utils/permissionGate";
import { getBrandTemplateCampus, getBlockVariantTypeDropDown } from "../../../../services/BrandTemplateServices";
import { values } from "underscore";
import { getStaticPagesStatusList } from "../../../../services/StaticPgesServer";
import SectionColorOverrideComponent from "../../SectionColorOverrideComponent";
import hasPermission from "../../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";

const EventDetails = ({ setHeaderName }) => {
  const { subId, subTab } = useParams();
  const history = useHistory();
  const [eventData, setEventData] = useState({});
  const [brandArr, setBrandArr] = useState([]);
  const [trackLength, setTrackLength] = useState("")
  const [schoolArr, setSchoolArr] = useState([]);
  const [eventCampusArr, setEventCampusArr] = useState([]);
  const [showTitle, setShowTitle] = useState(false);
  const [relatedSchoolArr, setRelatedSchoolArr] = useState([]);
  const [onCampus, setOnCampus] = useState(false);
  const [virtual, setVirtual] = useState(false);
  const [statusArr, setStatusArr] = useState([]);
  const [buttonLoading,setButtonLoading] = useState(false)
  const [popupArr, setPopupArr] = useState([]);
  const [collapsedTracks, setCollapsedTracks] = useState([]);
  const [learnignBlockArr, setLearnignBlockArr] = useState([]);
  const [testimonialsBlockArr, setTestimonialsBlockArr] = useState([]);
  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    getStaticPagesStatusList()
    .then((res) => { setStatusArr(res.data?.publishedStatus) })
    .catch(err => console.log(err))
  }, [])
  
  
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getUpcomingEventListDropDown();
        setSchoolArr(res.data.schoolList || []);
        setBrandArr(res.data.brandTemplateList || []);
        setRelatedSchoolArr(res.data.relatedSchool || []);
        setPopupArr(res.data.getPopupList || []);
        const [ BlockVariantTypeRes] = await Promise.all([
          getBlockVariantTypeDropDown()
        ]);
        const learnignBlockFilters = BlockVariantTypeRes.data.learnignBlock || [];
        const testimonialsBlockFilters = BlockVariantTypeRes.data.testimonialsBlock || [];
        setLearnignBlockArr(learnignBlockFilters);
        setTestimonialsBlockArr(testimonialsBlockFilters);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);
  
  
  
  useEffect(() => {
    let response = hasPermission({
      scopes: ["wbseventupcomingview"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }

    if ((subId && (subTab === "open"|| subTab == "update")) && response) {
      const fetchEventData = async () => {
        try {
          const res = await getUpcomingEventDetails(subId);
          const event = res.data.Data;
          event.brandTemplete = event.brandTemplete?.map((el) => el.BrandID);
          event.schools = event.schools?.map((el) => el.school_id);
          event.eventTrack = event.eventTrack?.map((track) => ({
            ...track,
            collapsed: false,
          }));
          if (event.EventTime) {
            const eventTimeParts = event.EventTime.split(":");
            if (eventTimeParts.length === 3) {
              const date = new Date();
              date.setHours(
                eventTimeParts[0],
                eventTimeParts[1],
                eventTimeParts[2]
              );
              event.EventTime = date;
            }
          }
          if (event.EndEventTime) {
            const endEventTimeParts = event.EndEventTime.split(":");
            if (endEventTimeParts.length === 3) {
              const date = new Date();
              date.setHours(
                endEventTimeParts[0],
                endEventTimeParts[1],
                endEventTimeParts[2]
              );
              event.EndEventTime = date;
            }
          }
          setEventData(event);
          setHeaderName(res.data?.Data?.EventTitle);
          localStorage.setItem("headerName", res.data?.Data?.EventTitle);
        } catch (error) {
          console.error(error);
          if (error.response.data?.message !== "") {
            Swal.fire({
              icon: "Info",
              title: "Info",
              text: "No record found with this id",
            })
            history.push(`/websiteManagement/events/upcoming_events/table`)
          }
        }
      };
      fetchEventData();
    }
  }, [subId]);
  
  
  const initialValues = {
    EventTitle: eventData?.EventTitle || "",
    EventDescription: eventData?.EventDescription || "",
    Brand: eventData?.brandTemplete || [],
    schools: eventData?.schools || [],
    ShowTitleInBody: eventData?.ShowTitleInBody || 0,
    BodyContent: eventData?.BodyContent || "",
    upcomingBodyContent: eventData?.upcomingBodyContent || "",
    SliderID: eventData?.SliderID || "",
    PopupID: eventData?.PopupID || "",
    event_type: eventData?.event_type || "",
    FormDestinationEmail: eventData?.FormDestinationEmail || "",
    conversion_code: eventData?.conversion_code || "",
    MetaTitle: eventData?.MetaTitle || "",
    MetaDescription: eventData?.MetaDescription || "",
    MetaKeywords: eventData?.MetaKeywords || "",
    isAddCustomCss: eventData?.isAddCustomCss || 0,
    customCss: eventData?.customCss || "",
    eventDate: eventData?.EventDate ? new Date(eventData?.EventDate) : null,
    EventTime: eventData?.EventTime || null,
    EndEventTime: eventData?.EndEventTime || null,
    campus_id: eventData?.campus_id || "",
    IsPublished: eventData?.IsPublished || 0,
    event_type: eventData?.event_type || 0,
    tracks: eventData?.eventTrack || [
      { CreatedAt: "", EventId: "", TrackName: "", School: "", TrackSchedule: "", collapsed: false, id: "" },
    ],
    school_category_show_block: eventData?.school_category_show_block || 0,
    school_category_search_bar: eventData?.school_category_search_bar || 0,
    school_category_text: eventData?.school_category_text || "",
    secondary_category_show_block: eventData?.secondary_category_show_block || 0,
    secondary_category_text: eventData?.secondary_category_text || "",
    upcoming_opendays_show_block: eventData?.upcoming_opendays_show_block || 0,
    upcoming_opendays_text: eventData?.upcoming_opendays_text || "",
    learning_method_show_block: eventData?.learning_method_show_block || 0,
    learning_method_block_variant: eventData?.learning_method_block_variant || "",
    learning_method_block_text: eventData?.learning_method_block_text || "",
    testimonials_block_show: eventData?.testimonials_block_show || 0,
    testimonials_block_variant: eventData?.testimonials_block_variant || "",
    testimonials_block_text: eventData?.testimonials_block_text || "",
    news_block_show: eventData?.news_block_show || 0,
    news_block_text: eventData?.news_block_text || "",
    
    school_override_color: eventData?.school_override_color || 0,
    school_accentColor: eventData?.school_accentColor || "#007BFF",
    school_textColorOne: eventData?.school_textColorOne || "#007BFF",
    school_textColorTwo: eventData?.school_textColorTwo || "#007BFF",
    school_backgroundColor: eventData?.school_backgroundColor || "#007BFF",
    openday_override_color: eventData?.openday_override_color || 0,
    openday_accentColor: eventData?.openday_accentColor || "#007BFF",
    openday_textColorOne: eventData?.openday_textColorOne || "#007BFF",
    openday_textColorTwo: eventData?.openday_textColorTwo || "#007BFF",
    openday_backgroundColor: eventData?.openday_backgroundColor || "#007BFF",
    learning_method_override_color: eventData?.learning_method_override_color || 0,
    learning_method_accentColor: eventData?.learning_method_accentColor || "#007BFF",
    learning_method_textColorOne: eventData?.learning_method_textColorOne || "#007BFF",
    learning_method_textColorTwo: eventData?.learning_method_textColorTwo || "#007BFF",
    learning_method_backgroundColor: eventData?.learning_method_backgroundColor || "#007BFF",
    testimonials_override_color: eventData?.testimonials_override_color || 0,
    testimonials_accentColor: eventData?.testimonials_accentColor || "#007BFF",
    testimonials_textColorOne: eventData?.testimonials_textColorOne || "#007BFF",
    testimonials_textColorTwo: eventData?.testimonials_textColorTwo || "#007BFF",
    testimonials_backgroundColor: eventData?.testimonials_backgroundColor || "#007BFF",
    news_override_color: eventData?.news_override_color || 0,
    news_accentColor: eventData?.news_accentColor || "#007BFF",
    news_textColorOne: eventData?.news_textColorOne || "#007BFF",
    news_textColorTwo: eventData?.news_textColorTwo || "#007BFF",
    news_backgroundColor: eventData?.news_backgroundColor || "#007BFF",
    toggle_brand_template: eventData?.toggle_brand_template || 0,
    groupByCourseLandingPages: eventData?.groupByCourseLandingPages || 0,
  };
  
  const validationSchema = Yup.object().shape({
    EventTitle: Yup.string()
    .required("Page Title is required")
    .trim()
    .nullable(),
    Brand: Yup.array().min(1, "At least one template is required"),
    BodyContent: Yup.string().required("Page Content is required"),
    eventDate: Yup.date().nullable().required("Event Date is required"),
    EventTime: Yup.date().nullable().required("Event Start Time is required"),
    EndEventTime: Yup.date().nullable().required("Event End Time is required"),
    eventDate: Yup.date().nullable().required("Event Date is required"),
    IsPublished: Yup.number().required("Status is required"),
    event_type: Yup.number().required("Event Type is required"),
    EventTime: Yup.date().nullable().required("Event Start Time is required"),
    upcomingBodyContent: Yup.string().required("Upcoming Event Content"),
    EndEventTime: Yup.date()
    .nullable()
    .required("Event End Time is required")
    .test(
      "is-greater",
      "End time must be after start time",
      function (value) {
        const { EventTime } = this.parent;
        return value && EventTime && value > EventTime;
      }
    ),
    tracks: showTitle && trackLength != 0 ? (Yup.array()
    .of(
      Yup.object({
        TrackName: Yup.string().required("Track Name is required"),
        School: Yup.string().required("Related School is required"),
        TrackSchedule: Yup.string().required("Scheduler is required"),
      })
    )
    .min(1, "At least one event track is required")) : ""
  });
  
  useEffect(() => {
  }, [values.track])
  useEffect(() => {
    getBrandTemplateCampus()
    .then((res) => {
      setEventCampusArr(res.data?.campusLocationFilters)
    }).catch((err) => {
      console.log("error:", err);
    })
  }, [])
  
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setButtonLoading(true);
      try {
        values.tracks = values.tracks.map((track, index) => ({
          ...track,
          EventId: subId,
          id: track.id || "",
          collapsed: collapsedTracks[index] || false,
        }));
        let date = moment(values?.eventDate).format("YYYY-MM-DD");
        let startTime = moment(values?.EventTime).format("HH:mm")
        let endTime = moment(values?.EndEventTime).format("HH:mm")
        // values.EventTime = moment(startTime).format("HH:mm");
        // values.EndEventTime = moment(endTime).format("HH:mm");
        const { eventDate, EventTime, EndEventTime, ...newValues } = values
        const data = {
          EventTime: startTime,
          EndEventTime: endTime,
          eventDate: date,
          ...newValues,
        }
        if (subTab === "create") {
          await addUpcomingEventPage(data);
          setButtonLoading(false)
          Swal.fire(
            "Success!",
            "Upcoming event created successfully!",
            "success"
          );
          history.push("/websiteManagement/events/upcoming_events/table");
        } else if (subTab === "open") {
          await updateUpcomingEventPage({ ...data, EventId: subId });
          setButtonLoading(false)
          Swal.fire(
            "Success!",
            "Upcoming event updated successfully!",
            "success"
          );
        }
      } catch (error) {
        Swal.fire(
          "Error!",
          `Failed to ${subTab === "create" ? "create" : "update"
          } upcoming event`,
          "error"
        );
        console.log(error);
      }
      setSubmitting(false);
    },
  });

  const handleEditorChange = (key, value) => {
    formik.setFieldValue(key, value);
  };
  useEffect(
    () => {
      setShowTitle(formik.values.ShowTitleInBody == 1);
    }, [formik.values.ShowTitleInBody]
  )
  
  const toggleCollapse = (index) => {
    formik.setFieldValue(
      `tracks[${index}].collapsed`,
      !formik.values.tracks[index].collapsed
    );
  };
  
  useEffect(() => {
    setTrackLength(formik.values.tracks.length)
  },[formik.values.tracks])
  
  
  
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };
  
  const handleSearchDateTime = (e) => {
    let searchedValue = e.target.value;
    let tempArr = document.getElementsByClassName(
      "react-datepicker__time-list-item"
    );
    let list = Array.from(tempArr);
    if (!list.length) return;
    list.forEach((item) => {
      if (!item.innerHTML.includes(searchedValue)) {
        item.classList.add("d-none");
      } else {
        item.classList.remove("d-none");
      }
    });
  };
  
  useEffect(() => {
    console.log(":::::::::",formik.values.event_type);
  },[formik.values.event_type])
  return (
    <div className="card card-body-inr">
    <FormikProvider value={formik}>
    <Form onSubmit={formik.handleSubmit}>
    <div className="row">
    <div className="col-md-12">
    <div className="edit-icon new-card-header">
    <h4 className="card-header">Event Information</h4>
    </div>
    </div>
    <FormField
    field={{ name: "EventTitle", label: "Event Title" }}
    formik={formik}
    col_lg={4}
    col_md={4}
    />
    <FormField
    field={{
      name: "EventDescription",
      label: "Event Description",
      
    }}
    required={false}
    formik={formik}
    col_lg={4}
    col_md={4}
    />
    <div className="col-md-4 col-lg-4">
    <div className="form-group-blk mb-3">
    <label htmlFor="CloseDate">Event Date* </label>
    <div className="datepicker-container">
    <DatePicker
    selected={formik.values.eventDate}
    onChange={(date) => formik.setFieldValue("eventDate", date)}
    minDate={new Date()}
    filterTime={filterPassedTime}
    dateFormat={FORM_DATE_FORMAT}
    showYearDropdown
    scrollableYearDropdown
    className={`form-control cursor-pointer ${formik.errors.eventDate && formik.touched.eventDate
      ? "is-invalid"
      : ""
    }`}
    title="Start Date"
    onBlur={formik.handleBlur}
    placeholderText="Select Date"
    timeIntervals={15}
    onChangeRaw={(e) => e.preventDefault()}
    />
    <i className="fal fa-calendar-alt"></i>
    </div>
    </div>
    </div>
    <div className="col-md-4 col-lg-4">
    <div className="form-group-blk mb-3">
    <label htmlFor="CloseDate">Event Start Time* </label>
    <div className="m-auto time-select datepicker-container">
    <DatePicker
    className={`form-control cursor-pointer ${formik.errors.EventTime && formik.touched.EventTime
      ? "is-invalid"
      : ""
    }`}
    selected={formik.values.EventTime}
    onChange={(date) => formik.setFieldValue("EventTime", date)}
    showTimeSelect
    showTimeSelectOnly
    filterTime={filterPassedTime}
    timeIntervals={5}
    timeCaption="Time"
    dateFormat={FORM_TIME_FORMAT}
    timeFormat={FORM_TIME_FORMAT}
    onChangeRaw={(e) => handleSearchDateTime(e)}
    title="Event Start Time"
    onBlur={formik.handleBlur}
    placeholderText="Select From Time"
    />
    <i className="fal fa-clock"></i>
    </div>
    </div>
    </div>
    <div className="col-md-4 col-lg-4">
    <div className="form-group-blk mb-3">
    <label htmlFor="CloseDate">Event End Time* </label>
    <div className="m-auto time-select datepicker-container">
    <DatePicker
    className={`form-control cursor-pointer ${formik.errors.EndEventTime && formik.touched.EndEventTime
      ? "is-invalid"
      : ""
    }`}
    selected={formik.values.EndEventTime}
    onChange={(date) =>
      formik.setFieldValue("EndEventTime", date)
    }
    showTimeSelect
    showTimeSelectOnly
    filterTime={filterPassedTime}
    timeIntervals={5}
    timeCaption="Time"
    dateFormat={FORM_TIME_FORMAT}
    timeFormat={FORM_TIME_FORMAT}
    onChangeRaw={(e) => handleSearchDateTime(e)}
    title="Event End Time"
    onBlur={formik.handleBlur}
    placeholderText="Select From Time"
    />
    <i className="fal fa-clock"></i>
    </div>
    </div>
    </div>
    
    <div className="col-md-4 col-lg-4" title="Assigned Brand Templates">
    <label htmlFor="Brand">
    Assigned Brand Templates *{" "}
    <i className="fal fa-info-circle"></i>
    </label>
    <div className="form-icon-group mb-4">
    <Select
    className={`form-control custom-select-box ${formik.touched.Brand && formik.errors.Brand && "is-invalid"
    }`}
    name="Brand"
    value={brandArr.filter((val) =>
      formik.values.Brand.includes(val.value)
    )}
    onChange={(value) =>
      formik.setFieldValue(
        "Brand",
        value ? value.map((v) => v.value) : []
      )
    }
    isClearable
    onBlur={formik.handleBlur}
    options={brandArr}
    maxMenuHeight={175}
    placeholder="Select"
    isMulti
    />
    </div>
    </div>
    <div className="col-md-4 col-lg-4" title="Event Campus">
    <label htmlFor="eventCampus">
    Event Campus {" "}
    <i className="fal fa-info-circle"></i>
    </label>
    <div className="form-icon-group mb-4">
    <Select
    className={`form-control custom-select-box ${formik.touched.campus_id && formik.errors.campus_id && "is-invalid"
    }`}
    name="campus_id"
    value={
      formik.values.campus_id
      ? eventCampusArr.find((val) => formik.values.campus_id == val.value)
      : ""
    }
    onChange={(value) => formik.setFieldValue("campus_id", value ? value.value : "")}
    isClearable
    onBlur={formik.handleBlur}
    options={eventCampusArr}
    maxMenuHeight={175}
    placeholder="Select"
    // isMulti
    />
    </div>
    </div>
    <div className="col-md-4 col-lg-4" title="Event Type">
    <label htmlFor="event_type">
    Event Type *
    <i className="fal fa-info-circle ml-1"></i>
    </label>
    <div className="form-icon-group mb-4">
    <Select
    className={`form-control custom-select-box ${formik.touched.event_type && formik.errors.event_type && "is-invalid"
    }`}
    name="event_type"
    value={
      formik.values.event_type !== "" && formik.values.event_type !== null
      ? [
        {
          value: 0,
          label: "On Campus",
        },
        {
          value: 1,
          label: "Virtual",
        },
        {
          value: 2,
          label: "Virtual & On Campus",
        },
      ].find((option) => formik.values.event_type == option.value)
      : ""
    }
    onChange={(value) =>
      formik.setFieldValue("event_type", value ? value.value : "")
    }
    isClearable
    onBlur={formik.handleBlur}
    options={[
      {
        value: 0,
        label: "On Campus",
      },
      {
        value: 1,
        label: "Virtual",
      },
      {
        value: 2,
        label: "Virtual & On Campus",
      }
    ]}
    maxMenuHeight={175}
    placeholder="Select"
    // isMulti
    />
    </div>
    </div>
    <div className="col-md-4 col-lg-4" title="Status">
    <label htmlFor="IsPublished">
    Status * {" "}
    <i className="fal fa-info-circle"></i>
    </label>
    <div className="form-icon-group mb-4">
    <Select
    className={`form-control custom-select-box ${formik.touched.IsPublished && formik.errors.IsPublished && "is-invalid"
    }`}
    name="IsPublished"
    value={
      formik.values.IsPublished !== "" && formik.values.IsPublished !== null
      ? statusArr.find((val) => formik.values.IsPublished == val.value)
      : ""
    }
    onChange={(value) =>
      formik.setFieldValue("IsPublished", value ? value.value : "")
    }
    onBlur={formik.handleBlur}
    options={statusArr}
    maxMenuHeight={175}
    placeholder="Select"
    isClearable
    // isMulti
    />
    </div>
    </div>
    
    {/* <FormField
      field={{
      name: "IsPublished",
      label: "Status",
      info: true,
      required: true,
      }}
      isClearable
      formik={formik}
      selectOptions={statusArr}
      /> */}
      
      
      <div className="col-md-12 col-lg-12" title="Assigned Schools">
      <label htmlFor="schools">
      Assigned Schools <i className="fal fa-info-circle"></i>
      </label>
      <div className="form-icon-group mb-4">
      <Select
      className={`form-control custom-select-box ${formik.touched.schools &&
        formik.errors.schools &&
        "is-invalid"
      }`}
      name="schools"
      value={schoolArr.filter((val) =>
        formik.values.schools.includes(val.value)
      )}
      onChange={(value) =>
        formik.setFieldValue(
          "schools",
          value ? value.map((v) => v.value) : []
        )
      }
      isClearable
      onBlur={formik.handleBlur}
      options={schoolArr}
      maxMenuHeight={175}
      placeholder="Select"
      isMulti
      />
      </div>
      </div>
      
      <div className="col-md-6 col-lg-6 d-flex align-items-center mb-4" title="Enable Event Schedule">
      <label className="mb-0">
      Enable Event Schedule <i className="fal fa-info-circle"></i>
      </label>
      <div className="toggle-switch ml-2">
      <label className="switch">
      <input
      type="checkbox"
      checked={formik.values.ShowTitleInBody === 1}
      onChange={(e) =>
        formik.setFieldValue(
          "ShowTitleInBody",
          e.target.checked ? 1 : 0
        )
      }
      />
      <span className="slider slider-round"></span>
      </label>
      </div>
      </div>
      
      <div className="col-md-12">
      <label htmlFor={"disclaimer_tc"} title="Event Content">Event Content *</label>
      <div className="form-icon-group mb-6 laptop-pricing" title="Event Content">
      <HtmlInputEditor
      editorState={formik.values.BodyContent}
      setEditorState={(value) =>
        handleEditorChange("BodyContent", value)
      }
      isInValid={formik.errors.BodyContent}
      isCKEditor={true}
      hideSign={true}
      />
      </div>
      </div>
      
      <div className="col-md-12">
      <label htmlFor={"disclaimer_tc"} title="Upcoming Event Content">Upcoming Event Content *</label>
      <div className="form-icon-group mb-6 laptop-pricing" title="Upcoming Event Content">
      <HtmlInputEditor
      editorState={formik.values.upcomingBodyContent}
      setEditorState={(value) =>
        handleEditorChange("upcomingBodyContent", value)
      }
      isInValid={formik.errors.upcomingBodyContent}
      isCKEditor={true}
      hideSign={true}
      />
      </div>
      </div>
      
      {/* Event Tracks Section */}
      {formik.values.ShowTitleInBody === 1 && <>
        <div className="col-md-12">
        <div className="edit-icon new-card-header">
        <h4 className="card-header">Event Tracks</h4>
        </div>
        </div>
        <FieldArray name="tracks">
        {({ push, remove }) => (
          <>
          {formik.values?.tracks?.map((track, index) => (
            <div key={index} className="col-md-12 mb-4">
            <div className="row align-items-center">
            <div className="col-md-10">
            <div className="edit-icon new-card-header">
            <h4 className="card-header">{`${track.collapsed ? "Minimized " : ""
            }Event Track ${index + 1}`}</h4>
            </div>
            </div>
            <div className="col-md-2 text-right">
            <button
            type="button"
            className="btn btn-link"
            onClick={() => toggleCollapse(index)}
            >
            {track.collapsed ? (
              <FontAwesomeIcon
              color="var(--topbar-color)"
              icon={faChevronDown}
              />
            ) : (
              <FontAwesomeIcon
              color="var(--topbar-color)"
              icon={faChevronUp}
              />
            )}
            </button>
            </div>
            </div>
            {!track.collapsed && (
              <>
              <div className="row align-items-center">
              <FormField
              field={{
                name: `tracks[${index}].TrackName`,
                label: `Event Track Name`,
                required: true,
                value:
                formik.values?.tracks?.[index]?.TrackName,
                isInvalid:
                formik.touched?.tracks?.[index]?.TrackName &&
                formik.errors?.tracks?.[index]?.TrackName,
              }}
              formik={formik}
              col_md={12}
              col_lg={6}
              />
              <FormField
              field={{
                name: `tracks[${index}].School`,
                label: `Related School`,
                value: formik.values?.tracks?.[index]?.School,
                info: true,
                isInvalid:
                formik.touched?.tracks?.[index]?.School &&
                formik.errors?.tracks?.[index]?.School,
              }}
              required={false}
              formik={formik}
              selectOptions={relatedSchoolArr}
              col_md={12}
              col_lg={6}
              />
              </div>
              <div className="row">
              <div className="col-md-12">
              <label htmlFor={`tracks[${index}].TrackSchedule`} title="Event Track Scheduler">
              Event Track Scheduler *
              </label>
              <div className="form-icon-group mb-4 laptop-pricing" title="Event Track Scheduler">
              <HtmlInputEditor
              editorState={
                formik.values?.tracks?.[index]
                ?.TrackSchedule
              }
              setEditorState={(value) => {
                handleEditorChange(
                  `tracks[${index}].TrackSchedule`,
                  value
                );
              }}
              isInValid={
                formik.errors.tracks?.[index]?.TrackSchedule
              }
              isCKEditor={true}
              hideSign={true}
              />
              </div>
              </div>
              </div>
              </>
            )}
            <div className="row">
            <div className="col-md-12 text-left">
            <button
            type="button"
            title="Remove Event Track"
            className="btn btn-danger"
            onClick={() => remove(index)}
            >
            <i className="fal fa-trash-alt"></i> Remove Event
            Track
            </button>
            </div>
            </div>
            </div>
          ))}
          <div className="col-md-12">
          <button
          type="button"
          title="Add Event Track"
          className="btn btn-primary"
          onClick={() =>
            push({
              TrackName: "",
              School: "",
              TrackSchedule: "",
              collapsed: false,
            })
          }
          >
          Add Event Track
          </button>
          </div>
          </>
        )}
        </FieldArray>
        </>}
        
        <div className="col-md-12">
        <hr
        className="border border-2"
        style={{ borderColor: "#E6EBF1" }}
        />
        </div>
        
        <div className="col-md-12">
        <div className="edit-icon new-card-header">
        <h4 className="card-header">Page Settings</h4>
        </div>
        </div>
        <FormField
        field={{ name: "PopupID", label: "Popup" }}
        required={false}
        formik={formik}
        selectOptions={popupArr}
        col_md={6}
        col_lg={6}
        />
        {/* <FormField
          field={{
          name: "FormDestinationEmail",
          label: "Form Emailss",
          required: false,
          }}
          formik={formik}
          col_md={6}
          col_lg={6}
          /> */}
          <div className="new-card-header col-md-12">
          <div className="row mb-4">
          <div className="col-md-12">
          <div className="edit-icon new-card-header">
          <h4 className="card-header">
          <i className="fal fa-cog" style={{ color: "var(--topbar-color)" }}></i> Section Settings                                        
          </h4>
          </div>
          </div>
          <div
          className="d-flex col-md-12" 
          title="Overwrite Section Content and settings of page components, this overwrites the settings on the brand template and is only applicable to this page."
          >
          <label className="mb-0">
          Override Brand Template Section Settings <i className="fal fa-info-circle grade-icon"></i>
          </label>
          <div className="toggle-switch ml-2">
          <label class="switch">
          <input
          type="checkbox"
          checked={formik.values.toggle_brand_template == 1 }
          onChange={(e) => {
            formik.setFieldValue(
              "toggle_brand_template", 
              e.target.checked ? 1 : 0
            );
          }}
          />
          <span class="slider slider-round"></span>
          </label>
          </div>
          </div>
          
          </div>
          </div>
          {formik.values.toggle_brand_template == 1 ? (<>
            <div className="col-12">
            <div className="row">
            <div className="col-md-12">
            <div className="edit-icon new-card-header">
            <h4 className="card-header">Schools Category Section </h4>
            </div>
            </div>
            
            
            <div className={`col-md-6 col-lg-3`}>
            <label htmlFor={"Section Settings"}>
            Section Settings
            </label>
            <div className="form-icon-group mt-3">
            <div className="custom-control custom-checkbox text-left mb-3 mr-3" title="Show Block">
            <input
            type="checkbox"
            className="custom-control-input"
            id="school_category_show_block"
            name="school_category_show_block"
            checked={formik.values.school_category_show_block == 1}
            onChange={(e) => {
              console.log("Updating school_category_show_block:", e.target.checked);
              formik.setFieldValue(
                "school_category_show_block",
                e.target.checked ? 1 : 0
              );
            }}
            />
            <label
            className="custom-control-label"
            htmlFor="school_category_show_block"
            >
            Show Section
            </label>
            </div>
            <div className="custom-control custom-checkbox text-left" title="Show Course Search Bar">
            <input
            type="checkbox"
            className="custom-control-input"
            id="school_category_search_bar"
            name="school_category_search_bar"
            checked={formik.values.school_category_search_bar == 1}
            onChange={(e) => {
              formik.setFieldValue(
                "school_category_search_bar",
                e.target.checked ? 1 : 0
              );
            }}
            />
            <label
            className="custom-control-label"
            htmlFor="school_category_search_bar"
            >
            Show Course Search Bar
            </label>
            </div>
            </div>
            </div>
            <div className="col-md-6 col-lg-9">
            <label htmlFor="school_category_text" title="Site Name">Section Title</label>
            <div className="form-icon-group" title="site_Name">
            <input
            type="text"
            className={`form-control ${formik.touched.school_category_text && formik.errors.school_category_text && "is-invalid"
            }`}
            name="school_category_text"
            id="school_category_text"
            title="Section Title"
            placeholder="Section Title"
            value={formik.values?.school_category_text}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            </div>
            </div>
            </div>
            <div
            className="d-flex my-20"
            title="Group Content by Course Landing Pages and not by schools"
            >
            <label className="mb-0">
            Group Content by Course Landing Pages
            <i className="fal fa-info-circle grade-icon ml-2"></i>
            {/* <a href={formik.values.external_link} target="_blank"><i className="fal fa-external-link color-sidebar ml-2"></i></a> */}
            </label>
            <div className="toggle-switch ml-2">
            <label className="switch">
            <input
            type="checkbox"
            checked={formik.values.groupByCourseLandingPages == 1}
            onChange={(e) => {
              formik.setFieldValue(
                'groupByCourseLandingPages',
                e.target.checked ? 1 : 0
              );
            }}
            />
            <span className="slider slider-round"></span>
            </label>
            </div>
            </div>
            <SectionColorOverrideComponent 
            prefix="school_" 
            formik={formik} 
            fields={["accentColor", "textColorOne", "textColorTwo", "backgroundColor"]} />
            <hr />
            </div>
            
            <div className="col-12">
            <div className="row pt-1">
            <div className="col-md-12">
            <div className="edit-icon new-card-header">
            <h4 className="card-header">Secondary Category Section</h4>
            </div>
            </div>
            
            <div className={`col-md-6 col-lg-3`}>
            <label htmlFor={"Section Settings"}>
            Section Settings
            </label>
            <div className="form-icon-group mt-3">
            <div className="custom-control custom-checkbox text-left mb-3 mr-3" title="Show Block">
            <input
            type="checkbox"
            className="custom-control-input"
            id="secondary_category_show_block"
            name="secondary_category_show_block"
            checked={formik.values.secondary_category_show_block == 1}
            onChange={(e) => {
              console.log("Updating secondary_category_show_block:", e.target.checked);
              formik.setFieldValue(
                "secondary_category_show_block",
                e.target.checked ? 1 : 0
              );
            }}
            />
            <label
            className="custom-control-label"
            htmlFor="secondary_category_show_block"
            >
            Show Section
            </label>
            </div>
            </div>
            </div>
            <div className="col-md-6 col-lg-9">
            <label htmlFor="secondary_category_text" title="Site Name">Section Title</label>
            <div className="form-icon-group" title="site_Name">
            <input
            type="text"
            className={`form-control ${formik.touched.secondary_category_text && formik.errors.secondary_category_text && "is-invalid"
            }`}
            name="secondary_category_text"
            id="secondary_category_text"
            title="Section Title"
            placeholder="Section Title"
            value={formik.values?.secondary_category_text}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            </div>
            </div>
            </div>
            <hr />
            </div>
            
            
            <div className="col-12">
            <div className="row pt-1">
            <div className="col-md-12">
            <div className="edit-icon new-card-header">
            <h4 className="card-header">Upcoming Open Days Block</h4>
            </div>
            </div>
            
            <div className={`col-md-6 col-lg-3`}>
            <label htmlFor={"Section Settings"}>
            Section Settings
            </label>
            <div className="form-icon-group mb-4 mt-3">
            <div className="custom-control custom-checkbox text-left mr-3" title="Show Block">
            <input
            type="checkbox"
            className="custom-control-input"
            id="upcoming_opendays_show_block"
            name="upcoming_opendays_show_block"
            checked={formik.values.upcoming_opendays_show_block == 1}
            onChange={(e) => {
              console.log("Updating upcoming_opendays_show_block:", e.target.checked);
              formik.setFieldValue(
                "upcoming_opendays_show_block",
                e.target.checked ? 1 : 0
              );
            }}
            />
            <label
            className="custom-control-label"
            htmlFor="upcoming_opendays_show_block"
            >
            Show Section
            </label>
            </div>
            </div>
            </div>
            <div className="col-md-6 col-lg-9">
            <label htmlFor="upcoming_opendays_text" title="Site Name">Section Title</label>
            <div className="form-icon-group" title="site_Name">
            <input
            type="text"
            className={`form-control ${formik.touched.upcoming_opendays_text && formik.errors.upcoming_opendays_text && "is-invalid"
            }`}
            name="upcoming_opendays_text"
            id="upcoming_opendays_text"
            title="Section Title"
            placeholder="Section Title"
            value={formik.values?.upcoming_opendays_text}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            </div>
            </div>
            </div>
            <SectionColorOverrideComponent 
            prefix="openday_" 
            formik={formik} 
            fields={["accentColor", "textColorOne", "textColorTwo", "backgroundColor"]} />
            <hr />
            </div>
            
            <div className="col-12">
            <div className="row pt-1">
            <div className="col-md-12">
            <div className="edit-icon new-card-header">
            <h4 className="card-header">Learning Methods Block</h4>
            </div>
            </div>
            
            <div className={`col-md-6 col-lg-3`}>
            <label htmlFor={"Section Settings"}>
            Section Settings
            </label>
            <div className="form-icon-group mb-4 mt-3">
            <div className="custom-control custom-checkbox text-left mr-3" title="Show Block">
            <input
            type="checkbox"
            className="custom-control-input"
            id="learning_method_show_block"
            name="learning_method_show_block"
            checked={formik.values.learning_method_show_block == 1}
            onChange={(e) => {
              console.log("Updating learning_method_show_block:", e.target.checked);
              formik.setFieldValue(
                "learning_method_show_block",
                e.target.checked ? 1 : 0
              );
            }}
            />
            <label
            className="custom-control-label"
            htmlFor="learning_method_show_block"
            >
            Show Section
            </label>
            </div>
            </div>
            </div>
            
            <div className="col-md-6 col-lg-4">
            <div className="form-group-blk mb-3" title="Section Variant">
            <label htmlFor="learning_method_block_variant">Section Variant <i className="fal fa-info-circle grade-icon"></i>
            </label>
            <Select
            className={
              "form-control custom-select-box " +
              (formik.errors.learning_method_block_variant && formik.touched.learning_method_block_variant
                ? " is-invalid"
                : "")
              }
              name="learning_method_block_variant"
              value={learnignBlockArr.filter(
                (item) => item.value == formik.values.learning_method_block_variant
              )}
              onChange={(value) => {
                if (value) {
                  formik.setFieldValue("learning_method_block_variant", value.value);
                } else {
                  formik.setFieldValue("learning_method_block_variant", "");
                }
              }}
              onBlur={formik.handleBlur}
              options={learnignBlockArr}
              maxMenuHeight={175}
              placeholder={"Select Section Variant"}
              />
              </div>
              </div>
              
              <div className="col-md-6 col-lg-4">
              <label htmlFor="learning_method_block_text" title="Site Name">Section Title</label>
              <div className="form-icon-group" title="site_Name">
              <input
              type="text"
              className={`form-control ${formik.touched.learning_method_block_text && formik.errors.learning_method_block_text && "is-invalid"
              }`}
              name="learning_method_block_text"
              id="learning_method_block_text"
              title="Section Title"
              placeholder="Section Title"
              value={formik.values?.learning_method_block_text}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              />
              </div>
              </div>
              </div>
              <SectionColorOverrideComponent 
              prefix="learning_method_" 
              formik={formik} 
              fields={["accentColor", "textColorOne", "textColorTwo", "backgroundColor"]} />
              <hr />
              </div>
              
              <div className="col-12">
              <div className="row pt-1">
              <div className="col-md-12">
              <div className="edit-icon new-card-header">
              <h4 className="card-header">Testimonials Block</h4>
              </div>
              </div>
              
              <div className={`col-md-6 col-lg-3`}>
              <label htmlFor={"Section Settings"}>
              Section Settings
              </label>
              <div className="form-icon-group mb-4 mt-3">
              <div className="custom-control custom-checkbox text-left mr-3" title="Show Block">
              <input
              type="checkbox"
              className="custom-control-input"
              id="testimonials_block_show"
              name="testimonials_block_show"
              checked={formik.values.testimonials_block_show == 1}
              onChange={(e) => {
                console.log("Updating testimonials_block_show:", e.target.checked);
                formik.setFieldValue(
                  "testimonials_block_show",
                  e.target.checked ? 1 : 0
                );
              }}
              />
              <label
              className="custom-control-label"
              htmlFor="testimonials_block_show"
              >
              Show Section
              </label>
              </div>
              </div>
              </div>
              
              <div className="col-md-6 col-lg-4">
              <div className="form-group-blk mb-3" title="Section Variant">
              <label htmlFor="testimonials_block_variant">Section Variant <i className="fal fa-info-circle grade-icon"></i>
              </label>
              <Select
              className={
                "form-control custom-select-box " +
                (formik.errors.testimonials_block_variant && formik.touched.testimonials_block_variant
                  ? " is-invalid"
                  : "")
                }
                name="Linked PMC Programme"
                value={testimonialsBlockArr.filter(
                  (item) => item.value == formik.values.testimonials_block_variant
                )}
                onChange={(value) => {
                  if (value) {
                    formik.setFieldValue("testimonials_block_variant", value.value);
                  } else {
                    formik.setFieldValue("testimonials_block_variant", "");
                  }
                }}
                onBlur={formik.handleBlur}
                options={testimonialsBlockArr}
                maxMenuHeight={175}
                placeholder={"Select Section Variant"}
                />
                </div>
                </div>
                
                <div className="col-md-6 col-lg-4">
                <label htmlFor="testimonials_block_text" title="Site Name">Section Title</label>
                <div className="form-icon-group" title="site_Name">
                <input
                type="text"
                className={`form-control ${formik.touched.testimonials_block_text && formik.errors.testimonials_block_text && "is-invalid"
                }`}
                name="testimonials_block_text"
                id="testimonials_block_text"
                title="Section Title"
                placeholder="Section Title"
                value={formik.values?.testimonials_block_text}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                </div>
                </div>
                </div>
                <SectionColorOverrideComponent 
                prefix="testimonials_" 
                formik={formik} 
                fields={["accentColor", "textColorOne", "textColorTwo", "backgroundColor"]} />
                <hr />
                </div>
                
                <div className="col-12">
                <div className="row pt-1">
                <div className="col-md-12">
                <div className="edit-icon new-card-header">
                <h4 className="card-header">News Block</h4>
                </div>
                </div>
                
                <div className={`col-md-6 col-lg-3`}>
                <label htmlFor={"Section Settings"}>
                Section Settings
                </label>
                <div className="form-icon-group mb-4 mt-3">
                <div className="custom-control custom-checkbox text-left mr-3" title="Show Block">
                <input
                type="checkbox"
                className="custom-control-input"
                id="news_block_show"
                name="news_block_show"
                checked={formik.values.news_block_show == 1}
                onChange={(e) => {
                  console.log("Updating news_block_show:", e.target.checked);
                  formik.setFieldValue(
                    "news_block_show",
                    e.target.checked ? 1 : 0
                  );
                }}
                />
                <label
                className="custom-control-label"
                htmlFor="news_block_show"
                >
                Show Section
                </label>
                </div>
                </div>
                </div>
                <div className="col-md-6 col-lg-9">
                <label htmlFor="news_block_text" title="Site Name">Section Title</label>
                <div className="form-icon-group" title="site_Name">
                <input
                type="text"
                className={`form-control ${formik.touched.news_block_text && formik.errors.news_block_text && "is-invalid"
                }`}
                name="news_block_text"
                id="news_block_text"
                title="Section Title"
                placeholder="Section Title"
                value={formik.values?.news_block_text}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                </div>
                </div>
                </div>
                <SectionColorOverrideComponent 
                prefix="news_" 
                formik={formik} 
                fields={["accentColor", "textColorOne", "textColorTwo", "backgroundColor"]} />
                </div>
                <hr/>
                </>): "" }
                <div className="col-md-12">
                <div className="edit-icon new-card-header">
                <h4 className="card-header">
                <i className="fal fa-cog" style={{ color: "var(--topbar-color)" }}></i>{" "}
                SEO Settings
                </h4>
                </div>
                </div>
                
                <FormField
                field={{
                  name: "conversion_code",
                  label: "Conversion Code",
                  
                }}
                required={false}
                formik={formik}
                col_md={6}
                col_lg={6}
                />
                <FormField
                field={{
                  name: "MetaTitle",
                  label: "Meta Title",
                  
                }}
                required={false}
                formik={formik}
                col_md={6}
                col_lg={6}
                />
                <FormField
                field={{
                  name: "MetaDescription",
                  label: "Meta Description",
                  
                }}
                required={false}
                formik={formik}
                col_md={6}
                col_lg={6}
                />
                <FormField
                field={{
                  name: "MetaKeywords",
                  label: "Meta Keywords",
                  
                }}
                required={false}
                formik={formik}
                col_md={6}
                col_lg={6}
                />
                
                <div className="col-md-12">
                <div className="edit-icon new-card-header">
                <h4 className="card-header">
                <i className="fal fa-cog" style={{ color: "var(--topbar-color)" }}></i>{" "}
                Style Settings
                </h4>
                </div>
                </div>
                <div className="col-md-6 col-lg-3 d-flex align-items-center mb-3">
                <label className="mb-0" title="Add Custom CSS">
                Add Custom CSS<i className="fal fa-info-circle"></i>
                </label>
                <div className="toggle-switch ml-2">
                <label className="switch">
                <input
                type="checkbox"
                title="Add Custom CSS"
                checked={formik.values.isAddCustomCss === 1}
                onChange={(e) =>
                  formik.setFieldValue(
                    "isAddCustomCss",
                    e.target.checked ? 1 : 0
                  )
                }
                />
                <span className="slider slider-round"></span>
                </label>
                </div>
                </div>
                
                {formik.values.isAddCustomCss ? (
                  <div className="col-md-12">
                  <div className="form-group-blk mb-3">
                  <>
                  <span className="my-2">Custom CSS *</span>
                  <div className="html-editor-input">
                  <textarea value={formik.values.customCss} className={`textarea form-control bg-white ${formik.touched.customCss && formik.errors.customCss && "is-invalid"}`} onBlur={formik.handleBlur} onChange={formik.handleChange} name="customCss"></textarea>
                  <div className="headerf">
                  <span className="title fw-bolds"> CSS</span>
                  </div>
                  </div>
                  </>
                  </div>
                  </div>
                ) : 
                null
              }
              </div>
              <div className="form-group form-group-save-cancel mt-4">
              <PermissionsGate scopes={[subTab == "create" ? 'wbseventupcomingadd' : 'wbseventupcomingedit']} RenderError={() => (
                <button type="submit" className="btn btn-primary" disabled>
                <i className="fal fa-save"></i>Save
                </button>
              )}>
              
              <button type="submit" className="btn btn-primary" disabled={buttonLoading}>
              {
                buttonLoading ? 
                <i className="fa-spin fal fa-cog"></i>
                :
                <i className="fal fa-save"></i>
              }Save
              </button>
              </PermissionsGate>
              </div>
              {formik.errors && (
                <div className="col-md-12 mt-3">
                {Object.keys(formik.touched).map((key) => {
                  if (key === "tracks") {
                    // Check if formik.errors.tracks is an array before mapping
                    const trackErrors = formik.errors.tracks;
                    if (Array.isArray(trackErrors)) {
                      return trackErrors.map((trackError, index) =>
                        trackError ? Object.keys(trackError).map((trackKey) => (
                        <div
                        className="invalid-feedback d-block"
                        key={`${index}-${trackKey}`}
                        >
                        Track {index + 1}: {trackError[trackKey]}
                        </div>
                      )) : null
                    );
                  }
                  return null; // Return null if trackErrors is not an array
                } else {
                  // Handle other fields
                  return (
                    formik.errors[key] && (
                      <div className="invalid-feedback d-block" key={key}>
                      {formik.errors[key]}
                      </div>
                    )
                  );
                }
              })}
              </div>
            )}
            </Form>
            </FormikProvider>
            </div>
          );
        };
        
        export default EventDetails;
        