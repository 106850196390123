import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { getBlockVariantTypeDropDown, getBrandTemplate, getBrandTemplateStatus, getHeaderDropDownListStatic, getHeaderDropdown, getPopUpListStatic, updateBrandTemplateHomePage } from "../../../../services/BrandTemplateServices";
import axios from "../../../../utils/axios";
import { FormField } from "../../../common/FormFields";
import SectionColorOverrideComponent from "../../SectionColorOverrideComponent.jsx";
import PermissionsGate from "../../../../utils/permissionGate.js";
import hasPermission from "../../../../utils/hasMultiplePermission.js";
import { useSelector } from "react-redux";


const BrandHome = () => {
    const { subId, subType } = useParams();
    const history = useHistory();
    const [statusArr, setStatusArr] = useState([]);
    const [popUpArr, setPopUpArr] = useState([]);
    const [headerArr, setHeaderArr] = useState([]);
    const [learnignBlockArr, setLearnignBlockArr] = useState([]);
    const [testimonialsBlockArr, setTestimonialsBlockArr] = useState([]);
    const [brandData, setBrandData] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [statusRes, headerRes, BlockVariantTypeRes, PopUpRes] = await Promise.all([
                    getBrandTemplateStatus(),
                    getHeaderDropDownListStatic(),
                    getBlockVariantTypeDropDown(),
                    getPopUpListStatic()
                ]);
                const statusFilters = statusRes.data.statusFilters || [];
                const popUpFilters = PopUpRes.data.Data || [];
                const headerFilters = headerRes.data.Data || [];
                const learnignBlockFilters = BlockVariantTypeRes.data.learnignBlock || [];
                const testimonialsBlockFilters = BlockVariantTypeRes.data.testimonialsBlock || [];
                setStatusArr(statusFilters);
                setHeaderArr(headerFilters);
                setPopUpArr(popUpFilters);
                setLearnignBlockArr(learnignBlockFilters);
                setTestimonialsBlockArr(testimonialsBlockFilters);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                }
            }
        };
        
        fetchData();
    }, []);
    

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({ scopes: ["wbssitebrandtmpview"], permissions: givenPermsisions, });
    if (!response) {
      history.push("/noaccess");
    }
  }, [])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getBrandTemplate(subId);
                setBrandData(res.data?.data);
            } catch (error) {
                // if (!axios.isCancel(error)) {
                    if (error.response.data?.message !== "") {
                        Swal.fire({
                            title: "Info",
                            text: "No record found with this ID",
                        });
                        history.push(`/websiteManagement/siteSetup/brand_Templates`);
                    }
                // }
            }
        };
        if (subId) {
            fetchData();
        }
    }, [subId]);
    
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            siteTitle: brandData.siteTitle || '',
            header: brandData.header || '',
            homePagePopUPID: brandData.homePagePopUPID !== null ? brandData.homePagePopUPID : '',
            analyticsCode: brandData.analyticsCode || '',
            facebook_pixel : brandData.facebook_pixel,
            metaKeywords: brandData.metaKeywords || '',
            metaDecription: brandData.metaDecription || '',
            
            homePagePrimaryCategoryText: brandData.homePagePrimaryCategoryText || '',
            isHomePagePrimaryCategoryShowBlock: brandData.isHomePagePrimaryCategoryShowBlock || 0,
            isHomePagePrimaryCategorySearchBarShow: brandData.isHomePagePrimaryCategorySearchBarShow || 0,
            
            homePageSecCategoryText: brandData.homePageSecCategoryText || '',
            isHomePageSecCategoryShowBlock: brandData.isHomePageSecCategoryShowBlock || 0,
            
            homePageUpcomingText: brandData.homePageUpcomingText || '',
            isHomePageUpcomingShowBlock: brandData.isHomePageUpcomingShowBlock || 0,
            
            homePageLearningVariant: brandData.homePageLearningVariant || '',
            homePageLearningText: brandData.homePageLearningText || '',
            isHomePageLearningShowBlock: brandData.isHomePageLearningShowBlock || 0,
            
            homePageTestimonialsVariant: brandData.homePageTestimonialsVariant || '',
            homePageTestimonialsText: brandData.homePageTestimonialsText || '',
            isHomePageTestimonialsShowBlock: brandData.isHomePageTestimonialsShowBlock || 0,
            
            homePageNewsText: brandData.homePageNewsText || '',
            isHomePageNewsShowBlock: brandData.isHomePageNewsShowBlock || 0,
            
            school_override_color: brandData?.school_override_color || 0,
            school_accentColor: brandData?.school_accentColor || "#007BFF",
            school_textColorOne: brandData?.school_textColorOne || "#007BFF",
            school_textColorTwo: brandData?.school_textColorTwo || "#007BFF",
            school_backgroundColor: brandData?.school_backgroundColor || "#007BFF",
            
            openday_override_color: brandData?.openday_override_color || 0,
            openday_accentColor: brandData?.openday_accentColor || "#007BFF",
            openday_textColorOne: brandData?.openday_textColorOne || "#007BFF",
            openday_textColorTwo: brandData?.openday_textColorTwo || "#007BFF",
            openday_backgroundColor: brandData?.openday_backgroundColor || "#007BFF",
            
            learning_method_override_color: brandData?.learning_method_override_color || 0,
            learning_method_accentColor: brandData?.learning_method_accentColor || "#007BFF",
            learning_method_textColorOne: brandData?.learning_method_textColorOne || "#007BFF",
            learning_method_textColorTwo: brandData?.learning_method_textColorTwo || "#007BFF",
            learning_method_backgroundColor: brandData?.learning_method_backgroundColor || "#007BFF",
            
            testimonials_override_color: brandData?.testimonials_override_color || 0,
            testimonials_accentColor: brandData?.testimonials_accentColor || "#007BFF",
            testimonials_textColorOne: brandData?.testimonials_textColorOne || "#007BFF",
            testimonials_textColorTwo: brandData?.testimonials_textColorTwo || "#007BFF",
            testimonials_backgroundColor: brandData?.testimonials_backgroundColor || "#007BFF",
            
            news_override_color: brandData?.news_override_color || 0,
            news_accentColor: brandData?.news_accentColor || "#007BFF",
            news_textColorOne: brandData?.news_textColorOne || "#007BFF",
            news_textColorTwo: brandData?.news_textColorTwo || "#007BFF",
            news_backgroundColor: brandData?.news_backgroundColor || "#007BFF",
            groupByCourseLandingPages: brandData?.groupByCourseLandingPages || 0
        },
        validationSchema: Yup.object().shape({
            header: Yup.string().required('Header is required').nullable().trim(),
            // homePagePopUPID: Yup.string().required('PopUP is required'),
            // analyticsCode: Yup.string(),
            // metaKeywords: Yup.string(),
            // metaTitle: Yup.string(),
            // metaDecription: Yup.string(),
            //homePagePrimaryCategoryText: Yup.string().required('Schools Category Section Title Text is required').nullable().trim(),
            //homePageSecCategoryText: Yup.string().required('Secondary Category Section Title Text is required').nullable().trim(),
            //homePageUpcomingText: Yup.string().required('Upcoming Open Days Section Title Text is required').nullable().trim(),
            //homePageLearningText: Yup.string().required('Learning Methods Section Title Text is required').nullable().trim(),
            //homePageTestimonialsText: Yup.string().required('Testimonials Section Title Text is required').nullable().trim(),
            //homePageNewsText: Yup.string().required('News Section Title Text is required').nullable().trim(),
            
        }),
        onSubmit: async (values, { setSubmitting }) => {
            if (subType === 'Create') {
                Swal.fire('Error!', 'Something went wrong', 'error');
            } else if (subType === 'update') {
                await updateBrandTemplateHomePage({ ...values, brandID: subId })
                .then(response => {
                    Swal.fire('Success!', 'Brand Template updated successfully!', 'success');
                    // history.push("/websiteManagement/siteSetup/brand_Templates");
                })
                .catch(error => {
                    Swal.fire('Error!', 'Failed to update Brand Template', 'error');
                });
            }
            setSubmitting(false);
        },
    });
    const { handleSubmit } = formik
    return (
        <div className="card card-body-inr">
        <form onSubmit={handleSubmit}>
        <div className="row">
        <div className="col-md-12">
        <div className="edit-icon new-card-header">
        <h4 className="card-header">Home Page Information</h4>
        </div>
        </div>
        <FormField field={{ name: "header", label: "Default Header" }} formik={formik} selectOptions={headerArr} />
        <FormField field={{ name: "homePagePopUPID", label: "Popup" }} formik={formik} selectOptions={popUpArr} />
        <div className="col-md-12">
        <hr className="border border-2" style={{ borderColor: "#E6EBF1" }} />
        </div>
        <div className="col-md-12">
        <div className="edit-icon new-card-header">
        <h4 className="card-header">Our Schools Section</h4>
        </div>
        </div>
        <div className={`col-md-6 col-lg-3`}>
        <label htmlFor={"Section Settings"}>
        Section Settings
        </label>
        <div className="form-icon-group mb-4 mt-3">
        <div className="custom-control custom-checkbox text-left mb-3 mr-3" title="Show Block">
        <input
        type="checkbox"
        className="custom-control-input"
        id="isHomePagePrimaryCategoryShowBlock"
        name="isHomePagePrimaryCategoryShowBlock"
        checked={formik.values.isHomePagePrimaryCategoryShowBlock == 1}
        onChange={(e) => {
            console.log("Updating isHomePagePrimaryCategoryShowBlock:", e.target.checked);
            formik.setFieldValue(
                "isHomePagePrimaryCategoryShowBlock",
                e.target.checked ? 1 : 0
            );
        }}
        />
        <label
        className="custom-control-label"
        htmlFor="isHomePagePrimaryCategoryShowBlock"
        >
        Show Section
        </label>
        </div>
        <div className="custom-control custom-checkbox text-left mb-3" title="Show Course Search Bar">
        <input
        type="checkbox"
        className="custom-control-input"
        id="isHomePagePrimaryCategorySearchBarShow"
        name="isHomePagePrimaryCategorySearchBarShow"
        checked={formik.values.isHomePagePrimaryCategorySearchBarShow == 1}
        onChange={(e) => {
            formik.setFieldValue(
                "isHomePagePrimaryCategorySearchBarShow",
                e.target.checked ? 1 : 0
            );
        }}
        />
        <label
        className="custom-control-label"
        htmlFor="isHomePagePrimaryCategorySearchBarShow"
        >
        Show Course Search Bar
        </label>
        </div>
        </div>        
        </div>        
        <FormField field={{ name: "homePagePrimaryCategoryText", label: "Section Title Text" }} formik={formik} col_md={6}
        col_lg={9} />
        <div className="col-md-12">
        <div
            className="d-flex my-20"
            title="Group Content by Course Landing Pages and not by schools"
            >
            <label className="mb-0">
            Group Content by Course Landing Pages
            <i className="fal fa-info-circle grade-icon ml-2"></i>
            {/* <a href={formik.values.external_link} target="_blank"><i className="fal fa-external-link color-sidebar ml-2"></i></a> */}
            </label>
            <div className="toggle-switch ml-2">
            <label className="switch">
            <input
            type="checkbox"
            checked={formik.values.groupByCourseLandingPages == 1}
            onChange={(e) => {
            formik.setFieldValue(
                'groupByCourseLandingPages',
                e.target.checked ? 1 : 0
            );
            }}
            />
            <span className="slider slider-round"></span>
            </label>
            </div>
            </div>
            </div>
        <div className="col-md-12">
        <SectionColorOverrideComponent 
        prefix="school_" 
        formik={formik} 
        fields={["accentColor", "textColorOne", "textColorTwo", "backgroundColor"]} />

        <hr className="border border-2" style={{ borderColor: "#E6EBF1" }} />
        </div>  
                            
        <div className="col-md-12">
        <div className="edit-icon new-card-header">
        <h4 className="card-header">Secondary Category Section</h4>
        </div>
        </div>
        <div className={`col-md-6 col-lg-3`}>
        <label htmlFor={"Section Settings"}>
        Section Settings
        </label>
        <div className="form-icon-group mb-4 mt-3">
        <div className="custom-control custom-checkbox text-left mb-3" title="Show Block">
        <input
        type="checkbox"
        className="custom-control-input"
        id="isHomePageSecCategoryShowBlock"
        name="isHomePageSecCategoryShowBlock"
        checked={formik.values.isHomePageSecCategoryShowBlock == 1}
        onChange={(e) => {
            formik.setFieldValue(
                "isHomePageSecCategoryShowBlock",
                e.target.checked ? 1 : 0
            );
        }}
        />
        <label
        className="custom-control-label"
        htmlFor="isHomePageSecCategoryShowBlock"
        >
        Show Section
        </label>
        </div>
        </div>
        
        </div>
        <FormField field={{ name: "homePageSecCategoryText", label: "Section Title Text" }} formik={formik} col_md={6}
        col_lg={9} />
        <div className="col-md-12">
        <hr className="border border-2" style={{ borderColor: "#E6EBF1" }} />
        </div>
        <div className="col-md-12">
        <div className="edit-icon new-card-header">
        <h4 className="card-header">Upcoming Open Days Section</h4>
        </div>
        </div>
        <div className={`col-md-6 col-lg-3`}>
        <label htmlFor={"Section Settings"}>
        Section Settings
        </label>
        <div className="form-icon-group mb-4 mt-3">
        <div className="custom-control custom-checkbox text-left mb-3" title="Show Block">
        <input
        type="checkbox"
        className="custom-control-input"
        id="isHomePageUpcomingShowBlock"
        name="isHomePageUpcomingShowBlock"
        checked={formik.values.isHomePageUpcomingShowBlock == 1}
        onChange={(e) => {
            formik.setFieldValue(
                "isHomePageUpcomingShowBlock",
                e.target.checked ? 1 : 0
            );
        }}
        />
        <label
        className="custom-control-label"
        htmlFor="isHomePageUpcomingShowBlock"
        >
        Show Section
        </label>
        </div>
        </div>
        
        </div>
        <FormField field={{ name: "homePageUpcomingText", label: "Section Title Text" }} formik={formik} col_md={6}
        col_lg={9} />
        <div className="col-md-12">
        <SectionColorOverrideComponent 
        prefix="openday_" 
        formik={formik} 
        fields={["accentColor", "textColorOne", "textColorTwo", "backgroundColor"]} />
        <hr className="border border-2" style={{ borderColor: "#E6EBF1" }} />
        </div>
        <div className="col-md-12">
        <div className="edit-icon new-card-header">
        <h4 className="card-header">Learning Methods Section</h4>
        </div>
        </div>
        <div className={`col-md-6 col-lg-3`}>
        <label htmlFor={"Section Settings"}>
        Section Settings
        </label>
        <div className="form-icon-group mb-4 mt-3">
        <div className="custom-control custom-checkbox text-left mb-3" title="Show Block">
        <input
        type="checkbox"
        className="custom-control-input"
        id="isHomePageLearningShowBlock"
        name="isHomePageLearningShowBlock"
        checked={formik.values.isHomePageLearningShowBlock == 1}
        onChange={(e) => {
            formik.setFieldValue(
                "isHomePageLearningShowBlock",
                e.target.checked ? 1 : 0
            );
        }}
        />
        <label
        className="custom-control-label"
        htmlFor="isHomePageLearningShowBlock"
        >
        Show Section
        </label>
        </div>
        </div>
        
        </div>
        <FormField field={{ name: "homePageLearningVariant", label: "Section Variant", required: false }} required={false} selectOptions={learnignBlockArr} formik={formik} col_md={6}
        col_lg={3} />
        <FormField field={{ name: "homePageLearningText", label: "Section Title Text" }} formik={formik} col_md={6}
        col_lg={6} />
        <div className="col-md-12">
        <SectionColorOverrideComponent 
        prefix="learning_method_" 
        formik={formik} 
        fields={["accentColor", "textColorOne", "textColorTwo", "backgroundColor"]} />
        <hr className="border border-2" style={{ borderColor: "#E6EBF1" }} />
        </div>
        <div className="col-md-12">
        <div className="edit-icon new-card-header">
        <h4 className="card-header">Testimonials Section</h4>
        </div>
        </div>
        <div className={`col-md-6 col-lg-3`}>
        <label htmlFor={"Section Settings"}>
        Section Settings
        </label>
        <div className="form-icon-group mb-4 mt-3">
        <div className="custom-control custom-checkbox text-left mb-3" title="Show Block">
        <input
        type="checkbox"
        className="custom-control-input"
        id="isHomePageTestimonialsShowBlock"
        name="isHomePageTestimonialsShowBlock"
        checked={formik.values.isHomePageTestimonialsShowBlock == 1}
        onChange={(e) => {
            formik.setFieldValue(
                "isHomePageTestimonialsShowBlock",
                e.target.checked ? 1 : 0
            );
        }}
        />
        <label
        className="custom-control-label"
        htmlFor="isHomePageTestimonialsShowBlock"
        >
        Show Section
        </label>
        </div>
        </div>
        
        </div>
        <FormField field={{ name: "homePageTestimonialsVariant", label: "Section Variant", required: false }} required={false} selectOptions={testimonialsBlockArr} formik={formik} col_md={6}
        col_lg={3} />
        <FormField field={{ name: "homePageTestimonialsText", label: "Section Title Text" }} formik={formik} col_md={6}
        col_lg={6} />
        <div className="col-md-12">
        <SectionColorOverrideComponent 
        prefix="testimonials_" 
        formik={formik} 
        fields={["accentColor", "textColorOne", "textColorTwo", "backgroundColor"]} />
        <hr className="border border-2" style={{ borderColor: "#E6EBF1" }} />
        </div>
        
        
        <div className="col-md-12">
        <div className="edit-icon new-card-header">
        <h4 className="card-header">News Section</h4>
        </div>
        </div>
        <div className={`col-md-6 col-lg-3`}>
        <label htmlFor={"Section Settings"}>
        Section Settings
        </label>
        <div className="form-icon-group mb-4 mt-3">
        <div className="custom-control custom-checkbox text-left mb-3" title="Show Block">
        <input
        type="checkbox"
        className="custom-control-input"
        id="isHomePageNewsShowBlock"
        name="isHomePageNewsShowBlock"
        checked={formik.values.isHomePageNewsShowBlock == 1}
        onChange={(e) => {
            formik.setFieldValue(
                "isHomePageNewsShowBlock",
                e.target.checked ? 1 : 0
            );
        }}
        />
        <label
        className="custom-control-label"
        htmlFor="isHomePageNewsShowBlock"
        >
        Show Section
        </label>
        </div>
        </div>
        
        </div>
        <FormField field={{ name: "homePageNewsText", label: "Section Title Text" }} formik={formik} col_md={6}
        col_lg={9} />
        <div className="col-md-12">
        <SectionColorOverrideComponent 
        prefix="news_" 
        formik={formik} 
        fields={["accentColor", "textColorOne", "textColorTwo", "backgroundColor"]} />
        <hr className="border border-2" style={{ borderColor: "#E6EBF1" }} />
        </div>
        <div className="col-md-12">
        <div className="edit-icon new-card-header">
        <h4 className="card-header"><FontAwesomeIcon icon={faGear} color="var(--topbar-color)" /> SEO Settings</h4>
        </div>
        </div>
        <FormField field={{ name: "analyticsCode", label: "Analytics Code", required: false }} formik={formik} required={false} />
        <FormField field={{ name: "metaTitle", label: "Meta Title", required: false }} formik={formik} required={false} />
        <FormField field={{ name: "metaDecription", label: "Meta Description", required: false }} formik={formik} required={false} />
        <FormField field={{ name: "metaKeywords", label: "Meta Keywords", required: false }} formik={formik} required={false} />
        <FormField field={{ name: "facebook_pixel", label: "Facebook Pixel", required: false }} formik={formik} required={false} />
        </div>
        <div className="form-group form-group-save-cancel">
        <PermissionsGate scopes={[subType == "create" ? 'wbssitebrandtmpadd' : 'wbssitebrandtmpedit']} RenderError={() => (
            <button className="btn btn-save btn-primary" disabled>
                <i className="fal fa-save"></i> Save
            </button>
        )}>
        <button className="btn btn-save btn-success" disabled={formik.isSubmitting} type="submit" title="Save">
        {formik.isSubmitting ? (
            <i className="fas fa-cog fa-spin"></i>
        ) : (
            <i className="fal fa-save"></i>
        )} Save
        </button>
        </PermissionsGate>
        {Object.keys(formik.values).map((key) => {
            if (formik.touched[key] && formik.errors[key]) {
                return (
                    <div className="invalid-feedback d-block">
                    {formik.errors[key]}
                    </div>
                );
            }
        })}
        </div>
        </form>
        </div>
    );
};

export default BrandHome;
