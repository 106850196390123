import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom';
import { RenderLearningMethod, RenderProgrammeType } from '../../../../utils/CommonGroupingItem';
import DataTableComponentFrontPagination from '../../../common/DataTableComponentFrontPagination';
import { RenderCourseAssignedlistStatus } from '../../../../utils/CommonStatusItems';
import SkeletonTicketList from '../../../../loaders/SkeletonTicketList';
import $ from 'jquery'

function CourseAvailableProgrames({ AvailablefilterData, availableRec, availableLoading, handleAdd }) {
  const [searchAssigned, setSearchAssigned] = useState("");
  const [availableStudyType, setAvailableStudyType] = useState({ arr: [], checkObj: {} })
  const [availableIntake, setAvailableIntake] = useState({ arr: [], checkObj: {} })

  const availableColumns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortField: "name",
      sortable: true,
      cell: (row) => (
        <Link
          className="as-text-blue curser feature-name"
          onClick={() => {$("#availableProgramme").modal("hide")}}
          to={`/websiteManagement/pages/programmes_and_short_courses/${row.page_type == 0 ? 'programme_pages' : 'short_course_page'}/details/open/${row.id}`}
          title={row.name}
        >
          <span className="textLimit100">{row.name}</span>
        </Link>
      ),
    },
    {
      name: "Status",
      selector: "status",
      sortField: "status",
      sortable: true,
      cell: (row) => (row.status !== undefined || null ? RenderCourseAssignedlistStatus(row.status).html : "-"),
    },
    {
      name: "Type",
      selector: "qualification_type_name",
      sortable: true,
      cell: (row) => row?.qualification_type_name != null ? RenderLearningMethod(row.qualification_type_name).html : "-"
    },
    {
      name: "Learning Method",
      selector: "study_type",
      sortable: true,
      sortField: "type",
      cell: (row) => (
       <div>
         {row?.getLearningMethods.length > 0 ? row.getLearningMethods.map((item)=>item.learning_method ? RenderLearningMethod(item.learning_method).html : "-") : "-"}
       </div>
          
      ),
  },
    {
      name: "Actions",
      selector: "",
      className: "permission-tabe-last-cell",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
          <Link
              className="btn btn-primary rounded-circle"
              onClick={() => {$("#availableProgramme").modal("hide")}}
              to={`/websiteManagement/pages/programmes_and_short_courses/${row.page_type == 0 ? 'programme_pages' : 'short_course_page'}/details/open/${row.id}`}
              title="Open"
            >
              <i className="fal fa-folder-open"></i>
            </Link>
            <button
              type="button"
              className="btn btn-sm btn-primary rounded-circle"
              onClick={() => handleAdd(row, "add")}
              title="Add"
            >
              <i className="fal fa-plus"></i>
            </button>
          </div>
        </div>
      ),
    },
  ]);

  const dataToRenderAvailable = () => {
    let updatedData = [];
    let allData = availableRec;

    if (searchAssigned.length) {
      let name = allData?.filter((item) => {
        let includes = item?.name?.toString()
          .toLowerCase()
          .includes(searchAssigned.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [...name];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (availableStudyType.arr.length) {
      let tempAssignStudy = updatedData;
      let tempIntakeHash = tempAssignStudy.filter((item) => {
        const startsMarking = availableStudyType.arr.find((it) => it == item.qualification_type)
        if (startsMarking) {
          return startsMarking;
        } else return null;
      });

      updatedData = tempIntakeHash;
    } else if (availableIntake.arr.length) {
      let tempIntake = updatedData;
      let tempIntakeHash = tempIntake.filter((item) => {
        const startsMarking = availableIntake.arr.find((it) => it == item.status)
        if (startsMarking) {
          return startsMarking;
        } else return null;
      });

      updatedData = tempIntakeHash;
    }

    if (
      searchAssigned.length ||
      availableIntake.arr.length ||
      availableStudyType.arr.length
    ) {
      return updatedData;
    } else {
      return availableRec;
    }
  };

  const exportAvailableData = () => {
    let data = dataToRenderAvailable();
    const header = [
      "Name",
      "Status",
      "Type",
      "Learning Methods"
    ];
    data = data.map((row) => ({
      "Name": row.name ? row.name : "-",
      "Status": row.status,
      "Type": row?.qualification_type_name != null ? RenderLearningMethod(row.qualification_type_name).text : "-",
      "Learning Methods": row?.getLearningMethods.length > 0 ? row.getLearningMethods.map((item)=>item.learning_method ? RenderLearningMethod(item.learning_method).text : "-").join(",") : "-",
    }));
    const pdfData = data.map((row) => {
      return [
        row["Name"],
        row["Status"],
        row["Type"],
        row["Learning Methods"]
      ];
    });
    return {
      header,
      data,
      pdfData,
      columnStyles: {}
    }
  };

  return (
    <>
      <div className="modal-content">
        <div className="modal-header modal-header-custom"><h5 className="modal-title"><i className="fal fa-plus"></i> Assign Programme</h5><button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button></div>
        <div className="modal-body">
          <DataTableComponentFrontPagination
            data={dataToRenderAvailable()}
            columns={availableColumns}
            loading={availableLoading}
            exportData={exportAvailableData}
            exportFileName={"Assign_Programmes"}
            defaultSort={{ defaultSortColumn: "name", defaultSortAsc: false }}
            custumcss="border-0"
            search={searchAssigned}
            progressComponent={<SkeletonTicketList />}
            setSearch={setSearchAssigned}
            filters={[
              {
                filterName: "Status",
                optionArr: AvailablefilterData.intake,
                state: availableIntake,
                setState: setAvailableIntake,
                uniqueId: "availableIntake",
                isOptionReversed: true,
                renderLabelFunction: RenderCourseAssignedlistStatus,
              },
              {
                filterName: "Type",
                optionArr: AvailablefilterData.learning,
                state: availableStudyType,
                setState: setAvailableStudyType,
                uniqueId: "availableStudyType",
                renderLabelFunction: RenderLearningMethod,
              }
            ]}
          />
        </div>
      </div>
    </>
  )
}

export default CourseAvailableProgrames