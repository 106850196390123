import React, { useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import Header from "../../common/Header";
import SideBar from "../../common/SideBar";
import AuditTrail from "../../StudentAdministration/Students/AuditTrail";
import CategoryInformation from "./CategoryInformation";
import LayoutForm from "../layoutMenus/LayoutForm";
import MenuItem from "../layoutMenuItem/MenuItem";
import HeaderInformation from "../layoutHeaders/HeaderInformation";
import AttachedPage from "../layoutHeaders/AttachedPage";
import LayoutTestimonialForm from "../layoutTestimonials/createTestimonial/LayoutTestimonialForm";
import { RenderStudentResourceType } from "../../../utils/CommonGroupingItem";
import { GetLayoutNavigationAuditTrailList, getLayoutNavigationAuditTrailListFilters } from "../../../services/WebsiteListServer";
import AuditTrailsTable from "../../common/AuditTrailsTable";
function CategoryTabs() {
    const history = useHistory();
    const {tab, subTab, subId="", type, id} = useParams();
     const [headerName, setHeaderName] = useState(() => {
        return localStorage.getItem("headerName") || "";
    });;


  const columnsToShow = ["Action Type", "Resource Type", "Resource Name"]
  let apiParam = {
    action_id: subId,
    // resource_type: ['Category']
    tab_type:"Testimonial"
  };

    return (
        <>
            <Header />
            <div className="content-wrapper d-flex flex-wrap">
                <SideBar />
                <div
                    className={
                        "sidebar-right " + (tab === "gradebook" ? "bottom-100" : "")
                    }
                >
                    <div className="sub-menu-content-block">
                        {/* <Tabs /> */}
                        <div className="sub-menu-right-block">
                            <div className="wrap-box-right">
                                <div className="my-tickets-heading-blk flex-direction mb-2">
                                    <div className="d-flex align-items-center ">
                                        <div className="name-w-head d-flex align-items-center ">
                                            <h4 class="text-left-align landing-heading heading_color_dynamic">
                                                {tab === "categories" ? "Categories: " : tab === "menus" ? "Menus: " : tab == "header" ? "Header: " : tab == "testimonials" ? "Testimonials: " : ""}
                                                <span>{type == "create" ? "Create New" : headerName}</span>
                                            </h4>
                                        </div>
                                        <div className="text-md-right action2-blk">
                                            <div className="ticket-view-btn d-flex align-items-center">
                                                <div className="add-new-ticket-bx">
                                                    <button
                                                        onClick={() => history.goBack()}
                                                        title="Back"
                                                        className="btn btn-white-bordered"
                                                    >
                                                        <i className="fal fa-angle-left"></i>Back
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tabs-wrap">
                                    { type !== "create" && <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                                        {(tab == "categories") && (
                                            <>
                                                <li className="nav-item" onClick={() => history.push(`/websiteManagement/layout_navigation/categories/category_information/open/${subId}`)}>
                                                    <Link
                                                        className={`nav-link ${(subTab === "category_information" && type == "open") && "active"}`}
                                                        id="pills-tab1"
                                                        data-toggle="pill"
                                                        to={`/websiteManagement/layout_navigation/categories/category_information/open/${subId}`}
                                                        role="tab"
                                                        aria-controls="pills-tab11"
                                                        aria-selected="true"
                                                    >
                                                        <i class="fa fa-info-circle"></i>
                                                        Category Information
                                                    </Link>
                                                </li>
                                                <li className="nav-item" onClick={() => history.push(`/websiteManagement/layout_navigation/categories/auditTrail/table/${subId}`)}>
                                                    <Link
                                                        className={`nav-link ${(tab == "categories" && subTab === "auditTrail") && "active"}`}
                                                        id="pills-tab2"
                                                        data-toggle="pill"
                                                        to={`/websiteManagement/layout_navigation/categories/auditTrail/table/${subId}`}
                                                        role="tab"
                                                        aria-controls="pills-tab12"
                                                        aria-selected="true"
                                                    >
                                                        <i className="fal fa-history"></i>Audit trail
                                                    </Link>
                                                </li>
                                            </>
                                        )}

                                        {(tab == "menus" && (subTab == "menu_setting" || subTab === "auditTrail" || subTab === "menu_items")) && (
                                            <>
                                                <li className="nav-item" onClick={() => history.push(`/websiteManagement/layout_navigation/menus/menu_setting/open/${subId}`)}>
                                                    <Link
                                                        className={`nav-link ${subTab == "menu_setting" && "active"}`}
                                                        id="pills-tab1"
                                                        data-toggle="pill"
                                                        to={`/websiteManagement/layout_navigation/menus/menu_setting/open/${subId}`}
                                                        role="tab"
                                                        aria-controls="pills-tab11"
                                                        aria-selected="true"
                                                        title="Menu Settings"
                                                    >
                                                        <i className="fa fa-info-circle"></i>
                                                        Menu Settings
                                                    </Link>
                                                </li>
                                                <li className="nav-item" onClick={() => history.push(`/websiteManagement/layout_navigation/menus/menu_items/table/${subId}`)}>
                                                    <Link
                                                        className={`nav-link ${subTab === "menu_items" && "active"}`}
                                                        id="pills-tab2"
                                                        data-toggle="pill"
                                                        to={`/websiteManagement/layout_navigation/menus/menu_items/table/${subId}`}
                                                        role="tab"
                                                        aria-controls="pills-tab12"
                                                        aria-selected="true"
                                                        title="Menu Items"
                                                    >
                                                        <i className="fa fa-bars"></i> Menu Items
                                                    </Link>
                                                </li>
                                                <li className="nav-item" onClick={() => history.push(`/websiteManagement/layout_navigation/menus/auditTrail/table/${subId}`)}>
                                                    <Link
                                                        className={`nav-link ${subTab === "auditTrail" && "active"}`}
                                                        id="pills-tab2"
                                                        data-toggle="pill"
                                                        to={`/websiteManagement/layout_navigation/menus/auditTrail/table/${subId}`}
                                                        role="tab"
                                                        aria-controls="pills-tab12"
                                                        aria-selected="true"
                                                        title="Audit trail"
                                                    >
                                                        <i className="fal fa-history"></i>Audit trail
                                                    </Link>
                                                </li>
                                            </>
                                        )}
                                        {tab === "header" && (
                                            <>
                                                <li className="nav-item" onClick={() => history.push(`/websiteManagement/layout_navigation/header/header_information/open/${subId}`)}>
                                                    <Link
                                                        className={`nav-link ${(tab == "header" && subTab == "header_information") && "active"}`}
                                                        id="pills-tab1"
                                                        data-toggle="pill"
                                                        to={`/websiteManagement/layout_navigation/header/header_information/open/${subId}`}
                                                        role="tab"
                                                        aria-controls="pills-tab11"
                                                        aria-selected="true"
                                                    >
                                                        <i class="fa fa-info-circle"></i>
                                                        Header Information
                                                    </Link>
                                                </li>
                                                <li className="nav-item" onClick={() => history.push(`/websiteManagement/layout_navigation/header/attached_page/table/${subId}`)}>
                                                    <Link
                                                        className={`nav-link ${(tab == "header" && subTab == "attached_page") && "active"}`}
                                                        id="pills-tab2"
                                                        data-toggle="pill"
                                                        to={`/websiteManagement/layout_navigation/header/attached_page/table/${subId}`}
                                                        role="tab"
                                                        aria-controls="pills-tab12"
                                                        aria-selected="true"
                                                    >
                                                        <i class="fa fa-file"></i>Attached Page
                                                    </Link>
                                                </li>
                                                <li className="nav-item" onClick={() => history.push(`/websiteManagement/layout_Navigation/header/auditTrail/table/${subId}`)}>
                                                    <Link
                                                        className={`nav-link ${(tab == "header" && subTab == "auditTrail") && "active"}`}
                                                        id="pills-tab2"
                                                        data-toggle="pill"
                                                        to={`/websiteManagement/layout_Navigation/header/auditTrail/table/${subId}`}
                                                        role="tab"
                                                        aria-controls="pills-tab12"
                                                        aria-selected="true"
                                                    >
                                                        <i className="fal fa-history"></i>Audit trail
                                                    </Link>
                                                </li>
                                            </>
                                        )}
                                        {( tab == "testimonials") && (
                                        <>
                                            <li className="nav-item" onClick={() => history.push(`/websiteManagement/layout_navigation/testimonials/testimonial_Information/open/${subId}`)}>
                                                <Link
                                                    className={`nav-link ${type == "create" ? "active" : type == "open" ? "active" : null}`}
                                                    id="pills-tab1"
                                                    data-toggle="pill"
                                                    to={`/websiteManagement/layout_navigation/testimonials/testimonial_Information/open/${subId}`}
                                                    role="tab"
                                                    aria-controls="pills-tab11"
                                                    aria-selected="true"
                                                >
                                                    <i className="fa fa-info-circle"></i>
                                                    Testimonial Information
                                                </Link>
                                            </li>
                                            <li className="nav-item" onClick={() => history.push(`/websiteManagement/layout_navigation/testimonials/auditTrail/table/${subId}`)}>
                                                <Link
                                                    className={`nav-link ${tab == "testimonials" && subTab === "auditTrail" && "active"}`}
                                                    id="pills-tab2"
                                                    data-toggle="pill"
                                                    to={`/websiteManagement/layout_navigation/testimonials/auditTrail/table/${subId}`}
                                                    role="tab"
                                                    aria-controls="pills-tab12"
                                                    aria-selected="true"
                                                >
                                                    <i className="fal fa-history"></i>Audit trail
                                                </Link>
                                            </li>
                                        </>
                                    )}
                                    </ul>}
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                                            {(tab == "categories" && subTab == "category_information" && type == "open") && <CategoryInformation setHeaderName={setHeaderName}/>}
                                            {(tab  == "categories" && subTab == "auditTrail" && type == "table") && <AuditTrail tabType = {"Category"} />}
                                            {(tab == "categories" && type == "create") && <CategoryInformation setHeaderName={setHeaderName}/>}
                                           
                                            {(tab == "menus" && type == "create") && <LayoutForm />}
                                            {(tab == "menus" && subTab == "menu_setting" && type == "open") && <LayoutForm id={subId} setHeaderName = {setHeaderName} />}
                                            {(tab == "menus" && subTab == "menu_items" && type == "table") && <MenuItem id={subId} />}
                                            {(tab == "menus" && subTab == "auditTrail" && type == "table") && <AuditTrail tabType = {"Menu"}   />}

                                            {(tab == "header" && subTab == "header_information") && <HeaderInformation setHeaderName={setHeaderName}/>}
                                            {(tab == "header" && subTab == "attached_page") && <AttachedPage />}
                                            {(tab == "header" && subTab == "auditTrail") && <AuditTrail tabType = {"Header"}  />}

                                            {( tab == "testimonials" && subTab == "testimonial_Information" && type == "create") && <LayoutTestimonialForm />}
                                            {( tab == "testimonials" && subTab == "testimonial_Information" && type == "open") && <LayoutTestimonialForm setHeaderName = {setHeaderName}/>}
                                            {( tab == "testimonials" && subTab == "auditTrail") && <AuditTrail tabType = {"Testimonial"}  />}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CategoryTabs;
