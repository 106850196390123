import axiosInstance from "../utils/axios";

export const GetListOfTickets = async (values) => {
  return await axiosInstance().post(`/listTickets`, values);
};

export const GetListOfStudents = async (values) => {
  return await axiosInstance().post(`/listStudents`, values);
};

export const GetListOfDepartments = async () => {
  return await axiosInstance().get(`/listDepartments`);
};

export const GetListOfDepartmentsCancelToken = async (cancelToken) => {
  return await axiosInstance().get(`/listDepartments`, { cancelToken });
};

export const GetListOfCategory = async (id) => {
  return await axiosInstance().post(`/listCategory`, { departmentId: id });
};

export const GetListOfCourses = async () => {
  return await axiosInstance().get(`/listCourses`);
};

export const ViewTicketDetails = async (id) => {
  return await axiosInstance().post(`/viewTicket`, { id_ticket: id });
};

export const CloseTicketService = async (values) => {
  return await axiosInstance().post(`/updateTicketStatus`, values);
};

export const AddTicketService = async (values) => {
  return await axiosInstance().post(`/addTicket`, values);
};

export const AddTicketAttachment = async (values) => {
  return await axiosInstance().post(`/addTicketAttachments`, values);
};

export const RemoveTicketAttachment = async (values) => {
  return await axiosInstance().delete(`/removeFeedAttachments/fileName`, values);
};

export const AddComment = async (values) => {
  return await axiosInstance().post(`/addComment`, values);
};

export const SearchTickets = async (values) => {
  return await axiosInstance().post(`/searchTickets`, values);
};

export const SearchStudent = async (values) => {
  return await axiosInstance().post(`/searchStudent`, values);
};

export const GetListOfAuditTrail = async () => {
  return await axiosInstance().get(`/auditTrailData`);
};

export const GetListOfAuditTrailCancelToken = async (cancelToken) => {
  return await axiosInstance().get(`/auditTrailData`, {
    cancelToken: cancelToken
  });
};

export const GetListofAllDepartment = async () => {
  return await axiosInstance().post(`/departmentList`, {});
};

export const Records = async (values) => {
  return await axiosInstance().post(`/listTicketForCsv`, values);
};

export const UpdateTicket = async (values) => {
  return await axiosInstance().post(`/updateTicket`, values);
};

export const RegistrationStudentSearchList = async () => {
  return await axiosInstance().get(`/registrationStudentSearchList`);
};

export const RegistrationStudentSearchListCancelToken = async (cancelToken) => {
  return await axiosInstance().get(`/registrationStudentSearchList`, { cancelToken });
};

export const GetTicketList = async (payload) => {
  return await axiosInstance().post(`/getTicketList`, payload);
}

export const TicketListIntakeSubject = async (data) => {
  return await axiosInstance().post(`/ticketListIntakeSubject`, data);
};

export const TicketListIntakeSubjectCancelToken = async (data, cancelToken) => {
  return await axiosInstance().post(`/ticketListIntakeSubject`, data, { cancelToken });
};

export const GetTicketAuditDataList = async (data, cancelToken) => {
  return await axiosInstance().post(`/getTicketAuditDataList`, data, { cancelToken });
};

export const GetTicketAuditDataListFilters = async (data) => {
  return await axiosInstance().get(`/getTicketAuditDataListFilters`, { params: data });
};
