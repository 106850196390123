import axiosInstance from "../utils/axios";

export const AddWebsite = async (data) => {
    console.log(data);
    return await axiosInstance().post('/insertWebsiteData', data)
}

export const updateWebsite = async (data, id) => {
    console.log(id);
    return await axiosInstance().post(`/updateWebsiteData?id=${id}`, data)
}

export const WebsiteBranch = async () => {
    return await axiosInstance().get(`/getBranchWebDropDownList`)
}
export const updatePartner = async (data) => {
    return await axiosInstance().post(`/updatePartner`, data);
};
export const getPartner = async (id) => {
    return await axiosInstance().get(`/getPartner?id=${id}`)
}

export const GetPartnerAuditTrialCancelToken = async (values, cancelToken) => {
    return await axiosInstance().post(`/getPartnerAuditTrial`, values, {
        cancelToken: cancelToken
    });
};
export const GetPartnerAuditTrialDropdownCancelToken = async (params,cancelToken) => {
    return await axiosInstance().get(`/getPartnerAuditDropdown`, { params, cancelToken: cancelToken });
};

export const getProgrammeOptions = async (params,cancelToken) => {
    return await axiosInstance().post(`/getProgrammeOptions`,params, { cancelToken: cancelToken });
};
export const getPartnersCancelToken = async (cancelToken) => {
    return await axiosInstance().get(`/getPartners`, {
        cancelToken: cancelToken
    });
};
export const deletePartner = async (id) => {
    return await axiosInstance().post(`/deletePartner`, { id: id })
}
export const WebsiteStatus = async () => {
    return await axiosInstance().get(`/getStatusDropDownList`)
}

export const WebsiteTemplates = async () => {
    return await axiosInstance().get(`/getBrandTemplateList`)
}

export const GetWebsiteListCancelToken = async (values, cancelToken) => {
    return await axiosInstance().post(`/getWebsiteList`, values, {
        cancelToken: cancelToken
    });
};

export const GetWebsiteListsFilterCancelToken = async (cancelToken) => {
    return await axiosInstance().get(`/getWebsiteListDropdown`, {
        cancelToken: cancelToken
    });
};

export const DeleteWebsiteList = async (values) => {
    console.log(values);
    return await axiosInstance().post(`/deleteWebsiteData?id=${values.id}`, values);
};

export const GetWebsiteList = async (data) => {
    return await axiosInstance().post(`/getWebsiteList`, data);
};

export const GetWebsiteUpdateList = async (id) => {
    return await axiosInstance().get(`getWebsiteData?id=${id}`);
};

export const GetEmailList = async (values, cancelToken) => {
    return await axiosInstance().post(`/getEmailsList`, values, {
        cancelToken: cancelToken
    });
};
export const GetEmailListFilters = async (cancelToken) => {
    return await axiosInstance().get(`/getEmailsListFilters`, {
        cancelToken: cancelToken
    });
};

export const GetLayoutNavigationAuditTrailList = async (values, cancelToken) => {
    return await axiosInstance().post(`/getLayoutNavigationAuditTrailList`, values, {
        cancelToken: cancelToken
    });
};

export const getLayoutNavigationAuditTrailListFilters = async (params, cancelToken) => {
    return await axiosInstance().get(`/getLayoutNavigationAuditTrailListFilters`, {
        params,
        cancelToken: cancelToken
    });
};

export const getEventsAuditTrailList = async (values, cancelToken) => {
    return await axiosInstance().post(`/getEventsAuditTrailList`, values, {
        cancelToken: cancelToken
    });
};
export const getEventsAuditTrailListFilters = async (params, cancelToken) => {
    return await axiosInstance().get(`/getEventsAuditTrailListFilters`, {
        params,
        cancelToken: cancelToken
    });
};
export const getPopupsAuditTrailList = async (params, cancelToken) => {
    return await axiosInstance().post(`/getPopupsAuditTrailList`, params, {
        
        cancelToken: cancelToken
    });
};
export const getPopupsAuditTrailListFilters = async (params, cancelToken) => {
    return await axiosInstance().get(`/getPopupsAuditTrailListFilters`, {
        params,
        cancelToken: cancelToken
    });
};

export const getCategoryPagesAuditTrailList = async (params, cancelToken) => {
    return await axiosInstance().post(`/getCategoryPagesAuditTrailList`, params, {
        cancelToken: cancelToken
    });
};
export const getCategoryPagesAuditTrailListFilters = async (params, cancelToken) => {
    return await axiosInstance().get(`/getCategoryPagesAuditTrailListFilters`, {
        params,
        cancelToken: cancelToken
    });
};
export const getStaticPageAuditTrailList = async (params, cancelToken) => {
    return await axiosInstance().post(`/getStaticPageAuditTrailList`, params, {
        cancelToken: cancelToken
    });
};
export const getStaticPageAuditTrailListFilters = async (params, cancelToken) => {
    return await axiosInstance().get(`/getStaticPageAuditTrailListFilters`, {
        params,
        cancelToken: cancelToken
    });
};
export const getCourseLandingPagesAuditTrailList = async (params, cancelToken) => {
    return await axiosInstance().post(`/getCourseLandingPagesAuditTrailList`, params, {
        cancelToken: cancelToken
    });
};
export const getCourseLandingPagesAuditTrailListFilters = async (params, cancelToken) => {
    return await axiosInstance().get(`/getCourseLandingPagesAuditTrailListFilters`, {
        params,
        cancelToken: cancelToken
    });
};
export const getProgrammeAndShortCoursesAuditTrailList = async (params, cancelToken) => {
    return await axiosInstance().post(`/getProgrammeAndShortCoursesAuditTrailList`, params, {
        cancelToken: cancelToken
    });
};
export const getProgrammeAndShortCoursesAuditTrailListFilters = async (params, cancelToken) => {
    return await axiosInstance().get(`/getProgrammeAndShortCoursesAuditTrailListFilters`, {
        params,
        cancelToken: cancelToken
    });
};
export const getCampusPagesAuditTrailList = async (params, cancelToken) => {
    return await axiosInstance().post(`/getCampusPagesAuditTrailList`, params, {
        cancelToken: cancelToken
    });
};
export const getCampusPagesAuditTrailListFilters = async (params, cancelToken) => {
    return await axiosInstance().get(`/getCampusPagesAuditTrailListFilters`, {
        params,
        cancelToken: cancelToken
    });
};
export const getBlogAndNewsTrailList = async (params, cancelToken) => {
    return await axiosInstance().post(`/getBlogAndNewsTrailList`, params, {
        cancelToken: cancelToken
    });
};
export const getBlogAndNewsAuditTrailListFilters = async (params, cancelToken) => {
    return await axiosInstance().get(`/getBlogAndNewsAuditTrailListFilters`, {
        params,
        cancelToken: cancelToken
    });
};
export const getWBSEmailsAuditTrailList = async (params, cancelToken) => {
    return await axiosInstance().post(`/getWBSEmailsAuditTrailList`, params, {
        cancelToken: cancelToken
    });
};
export const getWebsiteAuditTrailList = async (params, cancelToken) => {
    return await axiosInstance().post(`/getWebsiteAuditTrailList`, params, {
        cancelToken: cancelToken
    });
};
export const getWebsiteAuditTrailListFilters = async (params, cancelToken) => {
    return await axiosInstance().get(`/getWebsiteAuditTrailListFilters`, {
        params,
        cancelToken: cancelToken
    });
};
export const getWBSEmailsAuditTrailListFilters = async (params, cancelToken) => {
    return await axiosInstance().get(`/getWBSEmailsAuditTrailListFilters`, {
        params,
        cancelToken: cancelToken
    });
};



