import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom';
import { RenderPopupType } from '../../../../utils/CommonGroupingItem';
import DataTableComponentFrontPagination from '../../../common/DataTableComponentFrontPagination';
import { RenderCourseAssignedlistStatus } from '../../../../utils/CommonStatusItems';
import SkeletonTicketList from '../../../../loaders/SkeletonTicketList';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import $ from "jquery";

function PopupAvailableProgrames({ AvailablefilterData, availableRec, availableLoading, handleAdd }) {
    const [searchAssigned, setSearchAssigned] = useState("");
    const [availableStudyType, setAvailableStudyType] = useState({ arr: [], checkObj: {} })
    const [availableIntake, setAvailableIntake] = useState({ arr: [], checkObj: {} })
    const history=useHistory()
    
      

    const availableColumns = useMemo(() => [
        {
            name: "Name",
            selector: "title",
            sortField: "title",
            sortable: true,
            cell: (row) => (
                <Link
                    className="as-text-blue curser feature-name"
                    to={`${row.flag == 4 ? `/website_management/pages/categorylandingpages/table/update/${row.id}` : row.flag == 2 ? `/websiteManagement/pages/programmes_and_short_courses/short_course_page/details/open/${row.id}` : row.flag == 1 ? `/websiteManagement/pages/programmes_and_short_courses/programme_pages/details/open/${row.id}` : row.flag == 3 ? `/website_management/pages/staticpages/table/update/${row.id}` : row.flag == 5 ? `/website_management/pages/courselandingpages/table/update/${row.id}` : null}`}
                    onClick={() => $("#availableProgramme").modal("hide")}
                    title={row.title}
                >
                    <span className="textLimit100">{row.title}</span>
                </Link>

            ),
        },
        {
            name: "Status",
            selector: "status",
            sortField: "status",
            sortable: true,
            cell: (row) => (row.status !== undefined || null ? RenderCourseAssignedlistStatus(row.status).html : "-"),
        },
        {
            name: "Type",
            selector: "type",
            sortable: true,
            cell: (row) => row?.type ? RenderPopupType(row.type, "popup")?.html : "-"
        },
        {
            name: "Actions",
            selector: "",
            className: "permission-tabe-last-cell",
            cell: (row) => (
                <div className="assessment-08">
                    <div className="as-buttons">
                        <Link
                            // to={`/courseAdministration/Programmes/programme/open/${row.id}/details`}
                            to={`${row.flag == 4 ? `/website_management/pages/categorylandingpages/table/update/${row.id}` : row.flag == 2 ? `/websiteManagement/pages/programmes_and_short_courses/short_course_page/details/open/${row.id}` : row.flag == 1 ? `/websiteManagement/pages/programmes_and_short_courses/programme_pages/details/open/${row.id}` : row.flag == 3 ? `/website_management/pages/staticpages/table/update/${row.id}` : row.flag == 5 ? `/website_management/pages/courselandingpages/table/update/${row.id}` : null}`}
                            onClick={() => $("#availableProgramme").modal("hide")}
                            title="Open"
                            className="btn btn-primary rounded-circle"
                        >
                            <i className="fal fa-folder-open"></i>
                        </Link>
                        <button
                            type="button"
                            className="btn btn-sm btn-primary rounded-circle"
                            onClick={() => handleAdd(row, "add")}
                            title="Add"
                        >
                            <i className="fal fa-plus"></i>
                        </button>
                    </div>
                </div>
            ),
        },
    ]);

    const dataToRenderAvailable = () => {
        let updatedData = [...availableRec]; // Clone availableRec to avoid mutation
    
        // Filter by searchAssigned
        if (searchAssigned.length) {
            updatedData = updatedData.filter((item) =>
                item?.title?.toString().toLowerCase().includes(searchAssigned.toLowerCase())
            );
        }
    
        // Filter by availableStudyType
        if (availableStudyType.arr.length) {
            updatedData = updatedData.filter((item) =>
                availableStudyType.arr.includes(item.type)
            );
        }
    
        // Filter by availableIntake
        if (availableIntake.arr.length) {
            updatedData = updatedData.filter((item) =>
                availableIntake.arr.includes(item.status)
            );
        }
    
        // Return updated data or availableRec if no filters are applied
        return (
            searchAssigned.length ||
            availableIntake.arr.length ||
            availableStudyType.arr.length
        ) ? updatedData : availableRec;
    };
    

    const exportAvailableData = () => {
        let data = dataToRenderAvailable();
        const header = [
            "Name",
            "Status",
            "Type",
          ];
        data = data.map((row) => ({
            "Name": row.title ? row.title : "-",
            "Status": row.status,
            "Type": row?.type,
        }));
        
        const pdfData = data.map((row) => {
            return [
                row["Name"],
                row["Status"],
                row["Type"],
            ];
        });
        return {
            header,
            data,
            pdfData,
            columnStyles: {}
        }
    };

    return (
        <>
            <div className="modal-content">
                <div className="modal-header modal-header-custom"><h5 className="modal-title"><i className="fal fa-plus"></i> Assign Programme</h5><button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button></div>
                <div className="modal-body">
                    <DataTableComponentFrontPagination
                        data={dataToRenderAvailable()}
                        columns={availableColumns}
                        loading={availableLoading}
                        exportData={exportAvailableData}
                        exportFileName={"Assign Programme"}
                        defaultSort={{ defaultSortColumn: "name", defaultSortAsc: false }}
                        custumcss="border-0"
                        search={searchAssigned}
                        progressComponent={<SkeletonTicketList />}
                        setSearch={setSearchAssigned}
                        filters={[
                            {
                                filterName: "Status",
                                optionArr: AvailablefilterData.intake,
                                state: availableIntake,
                                setState: setAvailableIntake,
                                uniqueId: "availableIntake",
                                isOptionReversed: true,
                                renderLabelFunction: RenderCourseAssignedlistStatus,
                            },
                            {
                                filterName: "Type",
                                optionArr: AvailablefilterData.learning,
                                state: availableStudyType,
                                setState: setAvailableStudyType,
                                uniqueId: "availableStudyType",
                                renderLabelFunction: RenderPopupType,
                            }
                        ]}
                    />
                </div>
            </div>
        </>
    )
}

export default PopupAvailableProgrames