import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom';
import { RenderPopupType } from '../../../../utils/CommonGroupingItem';
import DataTableComponentFrontPagination from '../../../common/DataTableComponentFrontPagination';
import { RenderCourseAssignedlistStatus } from '../../../../utils/CommonStatusItems';
import SkeletonTicketList from '../../../../loaders/SkeletonTicketList';
import $ from "jquery"
import HtmlParser from 'react-html-parser';

const statusArr = [
    {
      value: "Unpublished",
      label: HtmlParser(
        `<span className="red" title="Unpublished">Unpublished</span>`
      ),
    },
    {
      value: "Draft",
      label: HtmlParser(`<span className="blue" title="Draft">Draft  </span> `),
    },
    {
      value: "Published",
      label: HtmlParser(
        `<span className="green" title="Published">Published</span>`
      ),
    },
  ];

function CategoryAvailableProgrames({ AvailablefilterData, availableRec, availableLoading, handleAdd }) {
    const [searchAssigned, setSearchAssigned] = useState("");
    const [availableStudyType, setAvailableStudyType] = useState({ arr: [], checkObj: {} })
    const [availableIntake, setAvailableIntake] = useState({ arr: [], checkObj: {} })

    const availableColumns = useMemo(() => [
        {
            name: "Name",
            selector: "title",
            sortField: "title",
            sortable: true,
            cell: (row) => (
                row.title ? <Link
                    className="as-text-blue curser feature-name"
                    to={`${row.flag == 1 ? `/websiteManagement/pages/programmes_and_short_courses/programme_pages/details/open/${row.id}` : row.flag == 2 && `/websiteManagement/pages/programmes_and_short_courses/short_course_page/details/open/${row.id}`}`}
                    title={row.title}
                    onClick={() => {
                        $("#availableProgramme").modal("hide");
                    }}
                >
                    <span className="textLimit100">{row.title}</span>
                </Link>
                :"-"
            ),
        },
        {
            name: "Status",
            selector: "status",
            sortField: "status",
            sortable: true,
            cell: (row) => (row.status ? RenderCourseAssignedlistStatus(row.status).html : "-"),
        },
        {
            name: "Type",
            selector: "type",
            sortable: true,
            cell: (row) => row?.type ? RenderPopupType(row.type, "popup")?.html : "-"
        },
        {
            name: "Actions",
            selector: "",
            className: "permission-tabe-last-cell",
            cell: (row) => (
                <div className="assessment-08">
                    <div className="as-buttons">
                        <button
                            type="button"
                            className="btn btn-sm btn-primary rounded-circle"
                            onClick={() => handleAdd(row, "add")}
                            title="Add"
                        >
                            <i className="fal fa-plus"></i>
                        </button>
                    </div>
                </div>
            ),
        },
    ]);

    const dataToRenderAvailable = () => {
        let updatedData = [];
        let allData = availableRec;

        if (searchAssigned.length) {
            let name = allData?.filter((item) => {
                let includes = item?.title?.toString()
                    .toLowerCase()
                    .includes(searchAssigned.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });

            let data = [...name];
            let unique = [...new Set(data)];

            updatedData = unique;
        } else {
            updatedData = allData;
        }

        if (availableStudyType.arr.length) {
            let tempAssignStudy = updatedData;
            let tempIntakeHash = tempAssignStudy.filter((item) => {
                const startsMarking = availableStudyType.arr.find((it) => it == item.type)
                if (startsMarking) {
                    return startsMarking;
                } else return null;
            });

            updatedData = tempIntakeHash;
        } else if (availableIntake.arr.length) {
            let tempIntake = updatedData;
            let tempIntakeHash = tempIntake.filter((item) => {
                const startsMarking = availableIntake.arr.find((it) => it == item.status)
                if (startsMarking) {
                    return startsMarking;
                } else return null;
            });

            updatedData = tempIntakeHash;
        }

        if (
            searchAssigned.length ||
            availableIntake.arr.length ||
            availableStudyType.arr.length
        ) {
            return updatedData;
        } else {
            return availableRec;
        }
    };

    const exportAvailableData = () => {
        let data = dataToRenderAvailable();
        const header = [
            "Name",
            "Status",
            "Type",
        ];
        data = data.map((row) => ({
            "Name": row.title ? row.title : "-",
            "Status": row.status,
            "Type": row?.type,
        }));

        const pdfData = data.map((row) => {
            return [
                row["Name"],
                row["Status"],
                row["Type"],
            ];
        });
        return {
            header,
            data,
            pdfData,
            columnStyles: {}
        }
    };

    return (
        <>
            <div className="modal-content">
                <div className="modal-header modal-header-custom"><h5 className="modal-title"><i className="fal fa-plus"></i> Assign Programme</h5><button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button></div>
                <div className="modal-body">
                    <DataTableComponentFrontPagination
                        data={dataToRenderAvailable()}
                        columns={availableColumns}
                        loading={availableLoading}
                        exportData={exportAvailableData}
                        exportFileName={"Assign Programme"}
                        defaultSort={{ defaultSortColumn: "name", defaultSortAsc: false }}
                        custumcss="border-0"
                        search={searchAssigned}
                        progressComponent={<SkeletonTicketList />}
                        setSearch={setSearchAssigned}
                        filters={[
                            {
                                filterName: "Status",
                                optionArr: AvailablefilterData.intake,
                                // optionArr: AvailablefilterData.intake || [],
                                state: availableIntake,
                                setState: setAvailableIntake,
                                uniqueId: "availableIntake",
                                isOptionReversed: true,
                                renderLabelFunction: RenderCourseAssignedlistStatus,
                            },
                            {
                                filterName: "Type",
                                optionArr: AvailablefilterData.learning,
                                state: availableStudyType,
                                setState: setAvailableStudyType,
                                uniqueId: "availableStudyType",
                                renderLabelFunction: RenderPopupType,
                            }
                        ]}
                    />
                </div>
            </div>
        </>
    )
}

export default CategoryAvailableProgrames