import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { TABLE_ROWS_PER_PAGE } from "../../../../utils/Constants";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import Tablefilter from "../../../common/Tablefilter";
import {
  RenderWebsiteBrandTemplate,
  UpcomingEventsStatus,
} from "../../../../utils/CommonStatusItems";
import axios from "axios";
import { handleTableScroll } from "../../../../utils/commonFunction";
import $ from "jquery";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import {
  getAssigneEventsFiltersList,
  getAssigneEventsList,
  getAssigneEventsListCancelToken,
} from "../../../../services/EventService";
import AssignedEventsPopupTable from "./AssignedEventsPopupTable";
import moment from "moment";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function AssignedEvents({ subId, eventsData, setEventsData }) {
  const [search, setSearch] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState("");
  const {id,subTab} = useParams()
  const [status, setStatus] = useState({ arr: [], checkObj: {} });
  const [type, setType] = useState({ arr: [], checkObj: {} });
  const [eventsData1, setEventsData1] = useState([]);
  const [loading, setloading] = useState(false)
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortkey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [filterData, setFilterData] = useState({  
    status: [],
    type: [],
  });

  useEffect(() => {
    handleTableScroll();
  }, [loading]);

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $(".rdt_TableCell").css("z-index", 0);
        $(this).parents(".rdt_TableCell").css("z-index", 22);
      });
    });
  });
  useEffect(() => {
    const storedFilters = JSON.parse(
      localStorage.getItem("filterStateAssigneEvents")
    );
    if (storedFilters) {
      setStatus(storedFilters.status);
      setFilterData(storedFilters.filterData);
    }
  }, []);
  useEffect(() => {
    const filterState = {
      status,
      filterData,
    };
    localStorage.setItem(
      "filterStateAssigneEvents",
      JSON.stringify(filterState)
    );
  }, [status, filterData]);
  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $(".rdt_TableCell").css("z-index", 0);
        $(this).parents(".rdt_TableCell").css("z-index", 22);
      });
    });
  });

  const addEvents = (row, type) => {
    let assigntemp = [...eventsData];
    let availtemp = [...eventsData1];
    if (type === "add") {
      assigntemp.push(row);
      availtemp = availtemp?.filter((data) => data.EventId !== row.EventId);
    } else if (type === "delete") {
      assigntemp = assigntemp?.filter((data) => data.EventId !== row.EventId);
      availtemp.push(row);
    }

    setEventsData([...assigntemp]);
    setEventsData1([...availtemp]);
  };
  useEffect(() => {
    const cancelTokenSources = [];
    const getUpcomingEventList = async () => {
      setloading(true);
      cancelTokenSources.forEach((source) => {
        source.cancel("New request made");
      });

      const source = axios.CancelToken.source();
      // setCancelTokenSources([...cancelTokenSources, source]);
      cancelTokenSources.push(source);

      const data = {
        page: page,
        perPage: perPage,
        keyval: sortkey,
        sort: sortOrder,
        search: search,
        exportStatus: true,
        viaStatus: status.arr,
        viaBrand: type.arr,
        emailID: subId || 0,
        isAssigned: 1,
      };

      try {
        const res = await getAssigneEventsListCancelToken(data, source.token);
        const event = res.data;
        setEventsData(event);
        if (res.status == 200) {
          setloading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }
    };
    if(subTab == "open"){
      getUpcomingEventList();
    }

    return () => {
      cancelTokenSources.forEach((source) => {
        source.cancel("Component unmounted");
      });
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getAssigneEventsFiltersList(subId, 1);
        setFilterData({
          ...res.data,
          status: res.data.eventItemStatusFilters
            ? res.data?.eventItemStatusFilters
            : [],
          type: res.data.eventTypesFilters ? res.data?.eventTypesFilters : [],
        });
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
    if(subTab == "open"){
      fetchData();
    }
  }, []);

  const resetFilter = () => {
    setStatus({ arr: [], checkObj: {} });
    setType({ arr: [], checkObj: {} });
    setSearch("");
  };
  const handleFilters = (e, type = "") => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  const dataToRender = () => {
    let updatedData = [];
    let allData = eventsData;
    if (search.length) {
      let tempFirstname = allData?.filter((item) => {
        let includes =
          item.EventTitle &&
          item.EventTitle?.toLowerCase()?.includes(search?.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let data = [...tempFirstname];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (status.arr.length) {
      let tempRole = updatedData;
      let tempSearch = tempRole?.filter((item) => {
        const startsWith = status?.arr?.find(function (user, index) {
          if (
            user?.toLowerCase() ===
            (item.status ? item?.status?.toLowerCase() : "")
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempSearch;
    }

    if (type.arr.length) {
      updatedData = updatedData?.filter((item) => {
        return item?.brandTemplete?.some((templateObj) =>
          type?.arr?.includes(String(templateObj?.BrandID))
        );
      });
    }
    return updatedData;
  };
  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "EventTitle",
      sortField: "EventTitle",
      sortable: true,
      // minWidth: "190px",
      cell: (row) => (
        <Link
          className="as-text-blue curser feature-name"
          to={`/websiteManagement/events/upcoming_events/event_details/open/${row.EventId}`}
          title={row.EventTitle}
        >
          <span className="textLimit100">{row.EventTitle}</span>
        </Link>
      ),
    },

    {
      name: "Status",
      selector: "status",
      sortField: "status",
      sortable: true,
      // maxWidth: "120px",
      // minWidth: "120px",
      cell: (row) => (row.status ? UpcomingEventsStatus(row?.status)?.html : "-"),
    },
    {
      name: "Brand Templates",
      selector: "brandTemplete",
      sortField: "brandTemplete",
      sortable: true,
      // maxWidth: "120px",
      // minWidth: "120px",
      cell: (row) => {
        if (row.brandTemplete.length) {
          return row.brandTemplete.map(
            (d, i) => RenderWebsiteBrandTemplate(d?.templateName)?.html
          );
        } else {
          return "-";
        }
      },
    },
    {
      name: "Event Date",
      selector: "EventDate",
      sortField: "EventDate",
      sortable: true,
      cell: (row) => moment(row?.EventDate)?.format("DD MMM YY") || "-",
    },
    {
      name: "Event Time",
      selector: "siteDomain",
      sortField: "siteDomain",
      sortable: true,
      cell: (row) => (
        <>
          <div className="">
            <p className="right-space">{`${(row.EventTime).replace(":00", "")}-${(row.EndEventTime).replace(":00", "")}`}</p>
          </div>
        </>
      ),
    },
    {
      name: "Bookings",
      selector: "booking_count",
      sortField: "booking_count",
      sortable: true,
      cell: (row) => (row.booking_count ? row.booking_count : "-"),
    },
    {
      name: "Actions",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons d-flex">
            <Link
              className="btn btn-primary h-auto rounded-circle"
              to={`/websiteManagement/events/upcoming_events/event_details/open/${row.EventId}`}
              title="Open"
            >
              <i className="fal fa-folder-open"></i>
            </Link>

            <button
              type="button"
              className="btn btn-danger rounded-circle"
              onClick={() => {
                Swal.fire({
                  title: "Are you sure?",
                  text: "You won't be able to revert this!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, delete it!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    addEvents(row, "delete");
                  }
                });
              }}
            >
              <i className="fal fa-trash-alt"></i>
            </button>
          </div>
        </div>
      ),
    },
  ]);

  const exportData = (fileType, fileName) => {
    let data = [];
    const header = [
      "Name",
      "Status",
      "Brand Templates",
      "Event Date",
      "Event Time",
      "Bookings",
    ];
    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    const eventsData = {
      page: page,
      perPage: perPage,
      keyval: sortkey,
      sort: sortOrder,
      search: search,
      exportStatus: true,
      viaStatus: status.arr,
      viaBrand: type.arr,
      emailID: subId,
      isAssigned: 1,
    };

    getAssigneEventsList(eventsData)
      .then((res) => {
        data = res.data;
        data = data?.map((row) => ({
          ...row,
          Name: row?.EventTitle || "",
          Status: row.status ? UpcomingEventsStatus(row.status).text : "-",

          "Brand Templates": row.brandTemplete.map(d => d.templateName).join(",") || "-",

          "Event Date": row?.EventDate
            ? moment(row?.EventDate)?.format("DD MMM YY")
            : "-",
          "Event Time": `${(row.EventTime).replace(":00", "")}-${(row.EndEventTime).replace(":00", "")}` || "-",
          Bookings: row.booking_count ? row.booking_count : "-",
        }));

        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          const blob = new Blob([csvString], {
            type: "text/csv;charset=utf-8,",
          });

          const blobURL = window.URL.createObjectURL(blob);

          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          setTimeout(() => {
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          ws1['!cols'] = [
            { wpx: 300 },
            { wpx: 300 },
            { wpx: 350 },
            { wpx: 320 },
            { wpx: 380 },
            { wpx: 300 },
          ];

          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          return false;
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [
              row?.EventTitle,
              row.status ? UpcomingEventsStatus(row.status).text : "-",
              row.brandTemplete.map(d => d.templateName).join(",") || "-",
              moment(row?.EventDate)?.format("DD MMM YY"),
              `${(row.EventTime).replace(":00", "")}-${(row.EndEventTime).replace(":00", "")}`,
              row.booking_count,
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              // valign: "center",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }
      })
      .catch((err) => {
        Swal.close();
        console.log(err);
      });
  };

  useEffect(() => {
    const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [debouncedTerm]);

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="custom-table-div filter-search-icon card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div
                    id="assessment-table-main_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      <input
                        type="search"
                        className=""
                        placeholder="Search"
                        aria-controls="assessment-table-main"
                        onChange={(e) => handleFilters(e, "search")}
                        value={search}
                      />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner filter-custom-new`}>
                    <Tablefilter
                      filterName="Status"
                      optionArr={filterData?.status}
                      state={status}
                      setState={setStatus}
                      renderLabelFunction={UpcomingEventsStatus}
                    />
                  </div>
                </div>
                {/* <div className="filter-scroll">
                  <div className={`filter-scroll-inner filter-custom-new`}>
                    <Tablefilter
                      filterName="Type"
                      optionArr={filterData?.type}
                      state={type}
                      setState={setType}
                    // renderLabelFunction={UpcomingEventsStatus}
                    />
                  </div>
                </div> */}
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={resetFilter}
                      title="Reset"
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "Assigned_event");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "Assigned_event");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "Assigned_event");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                <button
                  type="button"
                  className="btn btn-primary"
                  title="Assign Event"
                  data-toggle="modal"
                  data-target="#assignEvent"
                >
                  <i className="fal fa-calendar-alt"></i>Assign Event
                </button>
              </div>
            </div>
          </div>
        </div>
        {loading === true ? (
          <SkeletonTicketList />
        ) : (
          <DataTable
            data={dataToRender()}
            defaultSortField="EventTitle"
            defaultSortAsc={false}
            columns={columns}
            pagination={true}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />
        )}
      </div>
      <div
        className="topic-add-modal modal fade"
        id="assignEvent"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered available-assessment-modal"
          role="document"
        >
          <div className="modal-content">
            <div class="modal-header modal-header-custom">
              <h5 class="modal-title">
                <i class="fal fa-plus"></i> Assign Event
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <AssignedEventsPopupTable
              subId={subId}
              eventsData1={eventsData1}
              setEventsData1={setEventsData1}
              addEvents={addEvents}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssignedEvents;
