import React, { useState, useEffect, useRef } from "react";
import Header from "../../common/Header";
import SideBar from "../../common/SideBar";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { FormField, FileUploadField } from "../../common/FormFields";
import PermissionsGate from "../../../utils/permissionGate";
import {updatePartner, getPartner, getProgrammeOptions} from "../../../services/WebsiteListServer";
import { useParams } from 'react-router-dom';
const PartnerDetails = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [partnerData, setPartnerData] = useState({});
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [subsidiaryOptions, setSubsidiaryOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [programmeOptions, setProgrammeOptions] = useState([]);
  const [allProgrammeOptions, setAllProgrammeOptions] = useState([]);
  const [selectedSchools, setSelectedSchools] = useState([]);
  const [selectedSubsidiaries, setSelectedSubsidiaries] = useState([]);
  const [selectedProductTypes, setSelectedProductTypes] = useState([]);
  const [loadingProgrammeOptions, setLoadingProgrammeOptions] = useState(false);
  const cancelTokenSources = useRef([]);
  useEffect(() => {
    setLoadingProgrammeOptions(true);
    const data = {
      selectedSchools: selectedSchools,
      selectedSubsidiaries: selectedSubsidiaries,
      selectedProductTypes: selectedProductTypes,
    };
    // if any array is blank, then set programme options to null and return
    if (selectedSchools.length === 0 || selectedSubsidiaries.length === 0 || selectedProductTypes.length === 0) {
      setProgrammeOptions([]);
      setLoadingProgrammeOptions(false);
      return;
    }
    try {
      const res = getProgrammeOptions(data).then((res) => {
        // console.log("getProgrammeOptions", res);
        if(res?.data?.programmeOptions){
          setProgrammeOptions(res?.data?.programmeOptions);
        }
        if(res?.data?.allProgrammeOptions){
          setAllProgrammeOptions(res?.data?.allProgrammeOptions);
        }
        setLoadingProgrammeOptions(false);
      })
    } catch (error) {
        console.error('Error fetching programme options', error);
      setLoadingProgrammeOptions(false);
    }
  }, [selectedSubsidiaries, selectedSchools, selectedProductTypes]);
  
  useEffect(() => {
    
    try{
      if(id != 0){
        setLoading(true);  
        getPartner(id).then((res) => {
          // console.log("res", res);
          setPartnerData(res?.data?.data);
          setCampaignOptions(res?.data?.campaignOptions);
          setSubsidiaryOptions(res?.data?.subsidiaryOptions);
          setProductOptions(res?.data?.itemTypes);
          setSchoolOptions(res?.data?.schoolOptions);
          setSelectedSchools(res?.data?.data?.sellable_schools);
          setSelectedSubsidiaries(res?.data?.data?.subsidiaries);
          setSelectedProductTypes(res?.data?.data?.product_types);
          // console.log("PRoductTypes ", res?.data?.product_types, "schools ", res?.data?.sellable_schools, "subsidiaries ", res?.data?.subsidiaries);
          setLoading(false);
        });
      }
    }catch(err){
      setLoading(false);
    }
  }, [id]);
  
  useEffect(() => {
    
    try{
      if(id != 0){
        setLoading(true);    
        getPartner(id).then((res) => {
          // console.log("res", res);
          setPartnerData(res?.data?.data);
          setCampaignOptions(res?.data?.campaignOptions);
          setSubsidiaryOptions(res?.data?.subsidiaryOptions);
          setProductOptions(res?.data?.itemTypes);
          setSchoolOptions(res?.data?.schoolOptions);
          setSelectedSchools(res?.data?.data?.sellable_schools);
          setSelectedSubsidiaries(res?.data?.data?.subsidiaries);
          setSelectedProductTypes(res?.data?.data?.product_types);
          // console.log("1PRoductTypes ", res?.data?.product_types, "schools ", res?.data?.sellable_schools, "subsidiaries ", res?.data?.subsidiaries);
          setLoading(false);
        });
      }
    }catch(err){
      setLoading(false);
    }
  }, []);
  
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: id,
      name: partnerData?.name ? partnerData?.name : "",
      website: partnerData?.website ? partnerData?.website : "",
      referrer_link: partnerData?.referrer_link ? partnerData?.referrer_link : `https://partners.aie.ac/customer.php?id=${id}`,
      logo: partnerData?.logo ? partnerData?.logo : "",
      lead_source: partnerData?.lead_source ? partnerData?.lead_source : "",
      campaign: partnerData?.campaign ? partnerData?.campaign : "",
      subsidiaries: partnerData?.subsidiaries ? partnerData?.subsidiaries : "",
      product_types: partnerData?.product_types ? partnerData?.product_types : "",
      primary_color: partnerData?.primary_color ? partnerData.primary_color : "#ffffff",
      secondary_color: partnerData?.secondary_color ? partnerData.secondary_color : "#ffffff",
      tertiary_color: partnerData?.tertiary_color ? partnerData.tertiary_color : "#ffffff",
      form_background_color: partnerData?.form_background_color ? partnerData.form_background_color : "#ffffff",
      form_text_color: partnerData?.form_text_color ? partnerData.form_text_color : "#000000",
      form_button_color: partnerData?.form_button_color ? partnerData.form_button_color : "#000000",
      form_button_text_color: partnerData?.form_button_text_color ? partnerData.form_button_text_color : "#000000",
      form_heading_text_color: partnerData?.form_heading_text_color ? partnerData.form_heading_text_color : "#000000",
      form_disabled_field_color: partnerData?.form_disabled_field_color ? partnerData.form_disabled_field_color : "#000000",
      form_input_text_color: partnerData?.form_input_text_color ? partnerData.form_input_text_color : "#000000",
      form_input_color: partnerData?.form_input_color ? partnerData.form_input_color : "#000000",
      requestParentDetails: partnerData?.requestParentDetails || 0 ,
      status: partnerData?.status || 0,
      sellable_schools: partnerData?.sellable_schools || [],
      requestMatriculationYear: partnerData?.requestMatriculationYear || 0 ,
      hidden_programmes: partnerData?.hidden_programmes || [] ,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      subsidiaries: Yup.array().required("Subsidiaries is required"),
      product_types: Yup.array().required("Product types is required"),
      lead_source: Yup.number().required("Lead source is required"),
      sellable_schools: Yup.array().required("Sellable schools is required"),
    }),
    onSubmit: (values) => {
      const formData = new FormData();
      let trimmedValues = Object.fromEntries(
        Object.entries(values).map(([key, value]) => [
          key,
          typeof value === "string" ? value.trim().replace(/ +/g, " ") : value,
        ])
      );
      trimmedValues.subsidiaries = trimmedValues.subsidiaries?.length >0 ? trimmedValues.subsidiaries.join(",") : "";
      trimmedValues.product_types = trimmedValues.product_types?.length > 0 ? trimmedValues.product_types.join(",") : "";
      trimmedValues.sellable_schools = trimmedValues.sellable_schools?.length>0 ? trimmedValues.sellable_schools.join(",") : "";
      trimmedValues.hidden_programmes = trimmedValues.hidden_programmes?.length > 0 ? trimmedValues.hidden_programmes.join(",") : "";
      Object.keys(trimmedValues).forEach((key) => {
        if (Array.isArray(trimmedValues[key])) {
          trimmedValues[key].forEach((val, idx) => {
            formData.append(`${key}[${idx}]`, val);
          });
        } else {
          formData.append(key, trimmedValues[key]);
        }
      });
      updatePartner(formData, id)
      .then((response) => {
        Swal.fire("Added!", "Your record has been Saved.", "success");
        setLoading(false);
        history.push(`/website_management/partnerDetails/${response.data.id}`);
      })
      .catch((error) => {
        console.error("Error updating Partner:", error);
        Swal.fire("Error!", "Failed to update Partner", "error");
        setLoading(false);
      });
    },
  });
  
  return (
    <>
    <Header />
    <div className="content-wrapper d-flex flex-wrap">
    <SideBar />
    <div className="sidebar-right">
    <div className="sub-menu-content-block">
    <div className="sub-menu-right-block">
    <div className="wrap-box-right">
    <div className="my-tickets-heading-blk flex-direction mb-2">
    <div className="d-flex align-items-center">
    <div className="name-w-head d-flex align-items-center">
    <h4 className="text-left-align landing-heading heading_color_dynamic">
    {loading ? <>Loading...<i className="fas fa-cog fa-spin"></i></> : partnerData?.name ? <>Partner:<span> {partnerData?.name}</span></> : (<>Create <span>Partner</span></>)} {partnerData?.referrer_link ? <>-  <a href = {partnerData?.referrer_link} target="_blank">{partnerData?.referrer_link}</a></> : ""}
    </h4>
    </div>
    <div className="text-md-right action2-blk">
    <div className="ticket-view-btn d-flex align-items-center">
    <div className="add-new-ticket-bx">
    <button
    onClick={() => history.goBack()}
    title="Back"
    className="btn btn-white-bordered"
    >
    <i className="fal fa-angle-left"></i>Back
    </button>
    </div>
    </div>
    </div>
    </div>
    </div>
    
    <div className="tabs-wrap">
    <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
    <li className="nav-item">
    <div className="nav-link active" id="pills-tab1" role="tab" aria-selected="true">
    <i className="fal fa-info-circle"></i>Details
    </div>
    </li>
    </ul>
    
    <div className="tab-content" id="pills-tabContent">
    <div className="tab-pane fade active show" role="tabpanel">
    <div className="card card-body-inr">
    <form onSubmit={formik.handleSubmit}>
    <div className="my-tickets-info-list Tickets-main-wrap">
    <div className="edit-icon new-card-header">
    <div className="card-header">
    <strong>Partner Details</strong> {loading ? <><i className="fas fa-cog fa-spin"></i></> : ""} <span style={{color: '#87A3B3', fontStyle: 'italic'}}></span>
    </div>
    </div>
    
    <div className="row">
    <FormField col_lg={3} field={{ name: "name", label: "Name" }} formik={formik}/>
    <div className="col-md-6 col-lg-3">
    <label htmlFor="LeadSource">Status *</label>
    <div className="form-icon-group mb-3">
    <Select
    className={`form-control custom-select-box ${formik.touched.status && formik.errors.status && "is-invalid"
    }`}
    name="status"
    value={[{label:"Active", value: 0}, {label:"Disabled", value: 1}].find((item) => item.value === formik.values.status)}
    onChange={(option) => formik.setFieldValue("status", option.value)}
    onBlur={formik.handleBlur}
    options={[{label:"Active", value: 0}, {label:"Disabled", value: 1}]}
    maxMenuHeight={175}
    placeholder="Select Status"
    />
    </div>
    </div>
    {/* <FormField col_lg={2} field={{ name: "website", label: "Website" }} formik={formik} required={false}/> */}
    {/*Add dropdown field for lead_source, options are campaignOptions */}
    <div className="col-md-6 col-lg-3">
    <label htmlFor="LeadSource">Lead Source *</label>
    <div className="form-icon-group mb-3">
    <Select
    className={`form-control custom-select-box ${formik.touched.lead_source && formik.errors.lead_source && "is-invalid"
    }`}
    name="lead_source"
    value={campaignOptions.find((item) => item.value === formik.values.lead_source)}
    onChange={(option) => formik.setFieldValue("lead_source", option.value)}
    onBlur={formik.handleBlur}
    options={campaignOptions}
    maxMenuHeight={175}
    placeholder="Select Lead Source"
    />
    </div>
    </div>
    {/* <FormField field={{ name: "campaign", label: "Campaign" }} formik={formik} required={false}/> */}
    <div className="col-md-6 col-lg-3">
    <label htmlFor="subsidiaries">Assigned Subsidiaries * <i className="fal fa-info-circle"></i></label>
    <div className="form-icon-group mb-3">
    <Select
    className={`form-control custom-select-box ${formik.touched.subsidiaries && formik.errors.subsidiaries && "is-invalid"}`}
    name="subsidiaries"
    value={subsidiaryOptions.filter((val) => formik.values?.subsidiaries?.includes(val.value))}
    onChange={(value) => { 
      formik.setFieldValue( "subsidiaries", value ? value.map((v) => v.value) : []); 
      setSelectedSubsidiaries(value ? value.map((v) => v.value) : []);
    }}
    isClearable
    onBlur={formik.handleBlur}
    options={subsidiaryOptions}
    maxMenuHeight={175}
    placeholder="Select"
    isMulti
    />
    </div>
    </div>
    
    <div className="col-md-6 col-lg-3">
    <label htmlFor="product_types">Product Types * <i className="fal fa-info-circle"></i></label>
    <div className="form-icon-group mb-4">
    <Select
    className={`form-control custom-select-box ${formik.touched.product_types && formik.errors.product_types && "is-invalid"}`}
    name="product_types"
    value={productOptions.filter((val) => formik.values?.product_types?.includes(val.value))}
    onChange={(value) => { 
      formik.setFieldValue( "product_types", value ? value.map((v) => v.value) : []); 
      setSelectedProductTypes(value ? value.map((v) => v.value) : []);
    }}
    isClearable
    onBlur={formik.handleBlur}
    options={productOptions}
    maxMenuHeight={175}
    placeholder="Select"
    isMulti
    />
    </div>
    </div>
    <div className="col-md-6 col-lg-3">
    <label htmlFor="sellable_schools">Allowed Schools * <i className="fal fa-info-circle"></i></label>
    <div className="form-icon-group mb-4">
    <Select
    className={`form-control custom-select-box ${formik.touched.sellable_schools && formik.errors.sellable_schools && "is-invalid"}`}
    name="sellable_schools"
    value={schoolOptions.filter((val) => formik.values?.sellable_schools?.includes(val.value))}
    onChange={(value) => { 
      formik.setFieldValue( "sellable_schools", value ? value.map((v) => v.value) : []);
      setSelectedSchools(value ? value.map((v) => v.value) : []); 
    }}
    isClearable
    onBlur={formik.handleBlur}
    options={schoolOptions}
    maxMenuHeight={175}
    placeholder="Select"
    isMulti
    />
    </div>
    </div>
    <div title="Programmes the partner is not allowed to sell" className="col-md-6 col-lg-3">
    <label htmlFor="hidden_programmes">Hidden Programmes {loadingProgrammeOptions ? (<><i className="fas fa-cog fa-spin"></i></>) : (<> <i className="fal fa-info-circle"></i></>)}</label>
    <div className="form-icon-group mb-4">
    <Select
    className={`form-control custom-select-box ${formik.touched.hidden_programmes && formik.errors.hidden_programmes && "is-invalid"}`}
    name="hidden_programmes"
    value={allProgrammeOptions.filter((val) => formik.values?.hidden_programmes?.includes(val.value))}
    onChange={(value) => { 
      formik.setFieldValue( "hidden_programmes", value ? value.map((v) => v.value) : []);
    }}
    isClearable
    onBlur={formik.handleBlur}
    options={programmeOptions}
    maxMenuHeight={175}
    placeholder="Select"
    isMulti
    />
    </div>
    </div>
    <div className="col-md-6 col-lg-3">
    <div
    className="d-flex my-20"
    title="Add form inputs for parent name, mobile, email"
    style={{ paddingLeft: '15px'}}
    >
    <label className="mb-0">
    Request Parent Details
    <i className="fal fa-info-circle grade-icon ml-2"></i>
    </label>
    <div className="toggle-switch ml-2">
    <label className="switch">
    <input
    type="checkbox"
    checked={formik.values.requestParentDetails == 1}
    onChange={(e) => {
      formik.setFieldValue(
        'requestParentDetails',
        e.target.checked ? 1 : 0
      );
    }}
    />
    <span className="slider slider-round"></span>
    </label>
    </div>
    </div>    
    <div
    className="d-flex my-20"
    title="Add form Matriculation Year"
    style={{ paddingLeft: '15px'}}
    >
    <label className="mb-0">
    Request Matriculation Year
    <i className="fal fa-info-circle grade-icon ml-2"></i>
    </label>
    <div className="toggle-switch ml-2">
    <label className="switch">
    <input
    type="checkbox"
    checked={formik.values.requestMatriculationYear == 1}
    onChange={(e) => {
      formik.setFieldValue(
        'requestMatriculationYear',
        e.target.checked ? 1 : 0
      );
    }}
    />
    <span className="slider slider-round"></span>
    </label>
    </div>
    </div> 
    </div>
    </div>
    <hr />
    <div className="my-tickets-info-list Tickets-main-wrap">
    <div className="edit-icon new-card-header">
    <div className="card-header">
    <strong>Visuals</strong>
    </div>
    </div>
    <div className="row mt-2">
    {[
      { inputTitle:"Color of the page heading" ,label: "Form Heading Text Color", color: true, name: "form_heading_text_color", type: "color"},
      { inputTitle:"Text color of labels on form" ,label: "Form Text Color", color: true, name: "form_text_color", type: "color" },
      { inputTitle:"Color of inputs on form" ,label: "Form Input Color", color: true, name: "form_input_color", type: "color" },
      { inputTitle:"Text color of inputs on form" ,label: "Form Input Text Color", color: true, name: "form_input_text_color", type: "color" },            
      { inputTitle:"Color of the disabled Programmes field when shool has not been selected", label: "Form Disabled Field Color", color: true, name: "form_disabled_field_color", type: "color"},
      { inputTitle:"Background color of form, 80% transparency will be applied", label: "Form Background Color", color: true, name: "form_background_color", type: "color" },            
      { inputTitle:"Color of submit button on form" ,label: "Form Button Color", color: true, name: "form_button_color", type: "color"},
      { inputTitle:"Color of text on submit button" ,label: "Form Button Text Color", color: true, name: "form_button_text_color", type: "color"},            
      { inputTitle:"Used in the rotating background colors" ,label: "Rotary Color 1", color: true, name: "primary_color", type: "color" },
      { inputTitle:"Used in the rotating background colors" ,label: "Rotary Color 2", color: true, name: "secondary_color", type: "color" },
      { inputTitle:"Used in the rotating background colors" ,label: "Rotary Color 3", color: true, name: "tertiary_color", type: "color" }
    ].map((field, index) => (
      <FormField col_lg={3} key={index} field={field} formik={formik} />
    ))}
    <FileUploadField
    name={"logo"}
    label={"Logo"}
    formik={formik}
    accept=".png,.jpg,.jpeg"
    col_md={6}
    col_lg={3}
    required={false}
    />
    </div>
    
    <div className="form-group form-group-save-cancel mt-4">
    <PermissionsGate scopes={["partneredit"]} errorProps={{ disabled: true }}>
    <button
    className="btn btn-save btn-success"
    type="submit"
    title="Save"
    disabled={loading}
    onClick={formik.handleSubmit}
    >
    
    {loading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
    
    Save
    </button>
    </PermissionsGate>
    <button
    className="btn btn-close btn-danger"
    type="button"
    title="Cancel"
    onClick={() =>formik.resetForm()}
    >
    <i className="fal fa-times"></i>Cancel
    </button>
    </div>  
    <div className="col-md-12">
    </div>
    </div>
    </div>
    </form>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </>
  );
};

export default PartnerDetails;
