import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import Header from "../../common/Header";
import SideBar from "../../common/SideBar";
import { GetStudentDetailsCancelToken } from "../../../services/StudentsSettingServices";
import BrandInformation from "./brandtempletetab/BrandInformation";
import AuditTrail from "../../StudentAdministration/Students/AuditTrail";
import BrandHome from "./brandtempletetab/BrandHome";
import LaptopPricing from "./brandtempletetab/LaptopPricing";
import { getBrandTemplate } from "../../../services/BrandTemplateServices";
import { RenderStudentResourceType } from "../../../utils/CommonGroupingItem";
import { getWebsiteAuditTrailList, getWebsiteAuditTrailListFilters } from "../../../services/WebsiteListServer";
import AuditTrailsTable from "../../common/AuditTrailsTable";
// This will be shown in merge-all branch

function CreateBrandTemplate() {
    const history = useHistory();
    const { type, tab,id, subType, subId = "" } = useParams();
    const columnsToShow = ["Action Type", "Resource Type", "Resource Name"]
  const apiParams = {
    action_id: subId || id,
  }
     const [headerName, setHeaderName] = useState(() => {
        return localStorage.getItem("headerName") || "";
    });;
    return (
        <>
            <Header />
            <div className="content-wrapper d-flex flex-wrap">
                <SideBar />
                <div className={"sidebar-right " + (tab === "gradebook" ? "bottom-100" : "")}>
                    <div className="sub-menu-content-block">
                        <div className="sub-menu-right-block">
                            <div className="wrap-box-right">
                                <div className="my-tickets-heading-blk flex-direction mb-2">
                                    <div className="d-flex align-items-center ">
                                        <div className="name-w-head d-flex align-items-center ">
                                            <h4 className="text-left-align landing-heading heading_color_dynamic">
                                                {subType === "create" ? "Website Mangement: " : "Brand Template: "}
                                                <span>{subType === "create" ? "Create Brand Template" : headerName}</span>
                                            </h4>
                                        </div>
                                        <div className="text-md-right action2-blk">
                                            <div className="ticket-view-btn d-flex align-items-center">
                                                <div className="add-new-ticket-bx">
                                                    <button onClick={() => history.goBack()} title="Back" className="btn btn-white-bordered">
                                                        <i className="fal fa-angle-left"></i>Back
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tabs-wrap">
                                    {(subType === "update") && (
                                        <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                                            <li className="nav-item" title="Brand Information" onClick={() => history.push(`/websiteManagement/siteSetup/brand_Templates/brand_information/${subType}/${subId}`)}>
                                                <Link
                                                    className={`nav-link ${type === "brand_information" && "active"}`}
                                                    id="pills-tab1"
                                                    data-toggle="pill"
                                                    to={`/websiteManagement/siteSetup/brand_Templates/brand_information/${subType}/${subId}`}
                                                    role="tab"
                                                    aria-controls="pills-tab11"
                                                    aria-selected="true"
                                                >
                                                    <i class="fa fa-info-circle"></i>
                                                    Brand Information
                                                </Link>
                                            </li>
                                            <li className="nav-item" title="Home Page" onClick={() => history.push(`/websiteManagement/siteSetup/brand_Templates/home/${subType}/${subId}`)}>
                                                <Link
                                                    className={`nav-link ${type === "home" && "active"}`}
                                                    id="pills-tab2"
                                                    data-toggle="pill"
                                                    to={`/websiteManagement/siteSetup/brand_Templates/home/${subType}/${subId}`}
                                                    role="tab"
                                                    aria-controls="pills-tab12"
                                                    aria-selected="true"
                                                >
                                                    <i class="fa fa-home"></i> Home Page
                                                </Link>
                                            </li>
                                            <li className="nav-item" title="Laptop Pricing" onClick={() => history.push(`/websiteManagement/siteSetup/brand_Templates/laptop_pricing/${subType}/${subId}`)}>
                                                <Link
                                                    className={`nav-link ${type === "laptop_pricing" && "active"}`}
                                                    id="pills-tab2"
                                                    data-toggle="pill"
                                                    to={`/websiteManagement/siteSetup/brand_Templates/laptop_pricing/${subType}/${subId}`}
                                                    role="tab"
                                                    aria-controls="pills-tab12"
                                                    aria-selected="true"
                                                >
                                                    <i class="fa fa-laptop"></i>Laptop Pricing
                                                </Link>
                                            </li>
                                            <li className="nav-item" title="Audit trail" onClick={() => history.push(`/websiteManagement/siteSetup/brand_Templates/auditTrail/${subType}/${subId}`)}>
                                                <Link
                                                    className={`nav-link ${type === "auditTrail" && "active"}`}
                                                    id="pills-tab2"
                                                    data-toggle="pill"
                                                    to={`/websiteManagement/siteSetup/brand_Templates/auditTrail/${subType}/${subId}`}
                                                    role="tab"
                                                    aria-controls="pills-tab12"
                                                    aria-selected="true"
                                                >
                                                    <i className="fal fa-history"></i>Audit trail
                                                </Link>
                                            </li>
                                        </ul>
                                    )}
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                                            {type === "brand_information" && <BrandInformation setHeaderName={setHeaderName}/>}
                                            {type === "home" && <BrandHome />}
                                            {type === "laptop_pricing" && <LaptopPricing />}
                                            {type === "auditTrail" && <AuditTrailsTable
                                            apiFunction={getWebsiteAuditTrailList}
                                            filterArrApi={getWebsiteAuditTrailListFilters}
                                            columnsToShow={columnsToShow}
                                            exportFileName={"Brand template audit trails"}
                                            isResourceNameShown={true}
                                            apiParams={apiParams}
                                            actionId={subId || id}
                                            isShowingResourceTypeFilter
                                            renderResourceTypeFunction={RenderStudentResourceType}
                                            tabType={"Brand Template"}
                      />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreateBrandTemplate;
