import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import hasPermission from "../../../utils/hasMultiplePermission"
import HtmlInputEditor from "../../common/HtmlInputEditor";
import {
    getStaticPagesBrandList
} from "../../../services/StaticPgesServer";
import { getCampusLocationDropdown, getCampusPagesStatusList } from "../../../services/CampusPagesServer";
import { AddPopupPages, getAssigendPagesAndProgrammeShortcourse, GetPopupsUpdateList, updatePopupPages } from "../../../services/PopupsPagesServer";
import PopupAssignedProgrammes from "./assignedProgrammes/PopupAssignedProgrammes";
import axios from "axios";
import PermissionsGate from "../../../utils/permissionGate";

const PopupsPagesOpt = ({ id }) => {
    const history = useHistory();
    const { type } = useParams();
    const [updateData, setUpdateData] = useState({});
    const [brandList, setBrandList] = useState([]);
    const [linkedCampusData, setLinkedCampusData] = useState([]);
    const [assignedRec, setAssignedRec] = useState([]);
    const [assignloading, setAssignLoading] = useState(false);
    const [toggle,setToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [statusArr, setStatusArr] = useState([]);
    const [refresh, setRefresh] = useState(Date.now())

    const givenPermissions = useSelector((state) => state.givenPermission);

    let brandID = updateData?.brandTemplete?.map((data) => data?.brandID);

    useEffect(() => {
        const getProgramsList = async () => {
            setAssignLoading(true);

            try {
                let res = await getAssigendPagesAndProgrammeShortcourse(id, "0");

                if (res.status === 200) {
                    setAssignedRec(res.data && res?.data ? res?.data : []);
                    setAssignLoading(false);
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.log("error:", error)
                    setAssignLoading(false)
                }
            }
        }

        getProgramsList()
    }, [id, refresh])

    useEffect(() => {
        const checkPermissions = () => {
            const hasAccess = hasPermission({ scopes: ["pmcmedit"], permissions: givenPermissions });
            if (!hasAccess) {
                history.push("/noaccess");
            }
        };
        checkPermissions();
    }, [givenPermissions, history]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            getCampusPagesStatusList()
                .then(res => setStatusArr(res.data.publishedStatus))
                .catch(error => console.error("Error fetching status data", error))
                .finally(() => setLoading(false));

            getCampusLocationDropdown()
                .then(res => setLinkedCampusData(res.data.campusLocationFilters))
                .catch(error => console.error("Error fetching status data", error))
                .finally(() => setLoading(false));

            getStaticPagesBrandList()
                .then(res => setBrandList(res.data.brandTypesFilters))
                .catch(error => console.error("Error fetching status data", error))
                .finally(() => setLoading(false));
        };

        fetchData();
    }, [refresh]);

    const formik = useFormik({
        // enableReinitialize: true,
        initialValues: {
            PopUpID: "",
            PopUpName: "",
            IsDefault: 0,
            BodyContent: "",
            buttonText: "",
            buttonType: "",
            ButtonLink: "",
            checkCustomCss: 0,
            IsPublished: "",
            CustomCss: "",
            Brand: [],
            assignedPages: [],
        },
        validationSchema: Yup.object({
            PopUpName: Yup.string().required("Title is required"),
            Brand: Yup.array().required("Brand Templates is required"),
            BodyContent: Yup.string().required("Popup Content is required"),
            buttonText: Yup.string().required("Button Text is required"),
            buttonType: Yup.string().required("Button Type is required"),
            // CustomCss: Yup.string().when('checkCustomCss', {
            //     is: (value) => value == 1,
            //     then: Yup.string().required("Custom CSS is required"),
            //     otherwise: Yup.string()
            // })
        }),
        onSubmit: async (values, actions) => {
            const formData = new FormData();

            // Trim and clean values, replace undefined or null with empty string
            const cleanedValues = Object.fromEntries(
                Object.entries(values).map(([key, value]) => [
                    key,
                    value === undefined || value === null ? "" : value
                ])
            );

            // Append values to FormData
            Object.keys(cleanedValues).forEach((key) => {
                const value = cleanedValues[key];

                if (Array.isArray(value)) {
                    if (key === "assignedPages") {
                        formData.append(key, JSON.stringify(value));
                    } else {
                        value.forEach((val, idx) => {
                            formData.append(`${key}[${idx}]`, val !== undefined && val !== null ? val : "");
                        });
                    }
                } else {
                    formData.append(key, value !== undefined && value !== null ? value : "");
                }
            });
            

            try {
                if (type === "open") {
                    await updatePopupPages(formData);
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Updated Successfully",
                    });
                    setToggle(!toggle)
                    setRefresh(Date.now())
                } else {
                    await AddPopupPages(formData);
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Added Successfully",
                    });
                    setToggle(!toggle)
                    actions.resetForm();
                    history.push("/website_management/pages/popupspages/table");
                }
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: error.response?.data?.message || "An error occurred",
                });
            }
        }
    });


    useEffect(() => {
        const getUpdateData = () => {
            GetPopupsUpdateList(id)
                .then(res => setUpdateData(res?.data?.Data))
                .catch(err => {
                    if (type === 'open') {
                        Swal.fire({
                            title: "Info",
                            text: "No record found with this ID",
                        });
                        history.push(`/website_management/pages/popupspages/table`);
                    }
                })
        }

        { type === "open" && getUpdateData() }
    }, [id,toggle])
    useEffect(() => {
        let data = assignedRec.map((item) => ({
            id: item?.id,
            flag: item?.flag,
        }))
        formik.setFieldValue("assignedPages", data)
    }, [assignedRec])

    useEffect(() => {

        formik.setFieldValue("PopUpID", updateData?.PopUpID);
        formik.setFieldValue("PopUpName", updateData?.PopUpName);
        formik.setFieldValue("IsDefault", updateData?.IsDefault);
        formik.setFieldValue("BodyContent", updateData?.BodyContent);
        formik.setFieldValue("buttonText", updateData?.ButtonText);
        formik.setFieldValue("buttonType", updateData?.buttonType);
        formik.setFieldValue("ButtonLink", updateData?.ButtonLink);
        formik.setFieldValue("checkCustomCss", updateData?.checkCustomCss);
        formik.setFieldValue("IsPublished", type === "open" ? updateData?.IsPublished : '');
        formik.setFieldValue("CustomCss", updateData?.CustomCss);
        formik.setFieldValue("Brand", updateData?.brandTemplete?.map((item) => item.brandID) || []);



    }, [updateData, refresh]);

    return (
        <div className="card card-profile-info-card">
            <div>
                <div className="new-card-header">
                    <div className="card-header pt-0" title="Popup Information">Popup Information</div>
                </div>
                <div className="card-body-inr card-body-info">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group-blk mb-3" title="Title">
                                    <label>Title *</label>
                                    <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.PopUpName && formik.touched.PopUpName
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Title"
                                        name="PopUpName"
                                        id="PopUpName"
                                        value={formik.values.PopUpName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group-blk mb-3" title="Assigned Brand Templates">
                                    <label>Assigned Brand Templates* <i className="fal fa-info-circle"></i></label>
                                    <Select
                                        className={
                                            "form-control custom-select-box is-multi" + (formik.errors.Brand && formik.touched.Brand
                                                ? " is-invalid"
                                                : "")
                                        }
                                        name="Brand"
                                        value={brandList.filter((data) => formik.values.Brand.includes(data.value))}
                                        onChange={(value) => formik.setFieldValue("Brand", value ? value.map((v) => v.value) : [])}
                                        onBlur={formik.handleBlur}
                                        options={brandList}
                                        maxMenuHeight={175}
                                        isMulti
                                        placeholder={"Select Assigned Brand Templates"}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group-blk mb-3" title="Status">
                                    <label>Status <i className="fal fa-info-circle"></i></label>
                                    <Select
                                        className={
                                            "form-control custom-select-box" +
                                            (formik.errors.IsPublished && formik.touched.IsPublished
                                                ? " is-invalid"
                                                : "")
                                        }
                                        name="status"
                                        value={statusArr.find(val => val.value === formik.values.IsPublished)}
                                        onChange={(option) => {
                                            formik.setFieldValue("IsPublished", option ? option.value : "");
                                        }}
                                        onBlur={formik.handleBlur}
                                        options={statusArr}
                                        maxMenuHeight={175}
                                        placeholder={"Select Status"}
                                        isClearable
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-flex align-items-center" title="Default Popup">
                                <label className="mb-0">
                                    Default Popup <i className="fal fa-info-circle"></i>
                                </label>
                                <div className="toggle-switch ml-2">
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={formik.values.IsDefault}
                                            onChange={(e) => formik.setFieldValue("IsDefault", e.target.checked ? 1 : 0)}
                                        />
                                        <span className="slider slider-round"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group-blk mb-3" title="Popup Content">
                                    <label>Popup Content*</label>
                                    <HtmlInputEditor
                                        editorState={formik.values.BodyContent}
                                        setEditorState={(editorState) => formik.setFieldValue('BodyContent', editorState)}
                                        hideSign
                                        isCKEditor
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <hr className="border border-2" style={{ borderColor: "rgb(230, 235, 241)" }} />
                            </div>
                            <div className="new-card-header col-md-12">
                                <h4 className="card-header pt-0" title="Popup Settings">
                                    <i
                                        className="fal fa-cog"
                                        style={{ color: "var(--topbar-color)" }}
                                    ></i> Popup Settings
                                </h4>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group-blk mb-3" title="Button Text">
                                    <label>Button Text*</label>
                                    <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.buttonText && formik.touched.buttonText
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Button Text"
                                        name="buttonText"
                                        id="buttonText"
                                        value={formik.values.buttonText}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group-blk mb-3" title="Button Type">
                                    <label>Button Type *</label>
                                    <Select
                                        className={
                                            "form-control custom-select-box" +
                                            (formik.errors.buttonType && formik.touched.buttonType
                                                ? " is-invalid"
                                                : "")
                                        }
                                        name="buttonType"
                                        value={linkedCampusData.find(val => val.value === formik.values.buttonType)}
                                        onChange={(option) => {
                                            formik.setFieldValue("buttonType", option ? option.value : "");
                                        }}
                                        onBlur={formik.handleBlur}
                                        options={linkedCampusData}
                                        maxMenuHeight={175}
                                        placeholder={"Select Button Type"}
                                        isClearable
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group-blk mb-3" title="Button URL">
                                    <label>Button URL</label>
                                    <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.ButtonLink && formik.touched.ButtonLink
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Button URL"
                                        name="ButtonLink"
                                        id="ButtonLink"
                                        value={formik.values.ButtonLink}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="edit-icon new-card-header mb-4 mt-1" title="Style Settings">
                                    <h4 className="card-header">
                                        <i
                                            className="fal fa-cog"
                                            style={{ color: "var(--topbar-color)" }}
                                        ></i> Style Settings
                                    </h4>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-flex align-items-center mb-3" title="Add Custom CSS">
                                <label className="mb-0">
                                    Add Custom CSS <i className="fal fa-info-circle"></i>
                                </label>
                                <div className="toggle-switch ml-2">
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={formik.values.checkCustomCss}
                                            onChange={(e) => formik.setFieldValue("checkCustomCss", e.target.checked ? 1 : 0)}
                                        />
                                        <span className="slider slider-round"></span>
                                    </label>
                                </div>
                            </div>
                            {formik.values.checkCustomCss == 1 && <div className="col-md-12">
                                <div className=" mb-3 mt-3" title="Custom CSS">
                                    <label>Custom CSS*</label>
                                    <div className="html-editor-input">
                                        <textarea value={formik.values.CustomCss} className={`textarea bg-white form-control ${formik.touched.CustomCss && formik.errors.CustomCss && "is-invalid"}`} onBlur={formik.handleBlur} onChange={formik.handleChange} name="CustomCss"></textarea>
                                        <div className="headerf">
                                            <span className="title fw-bolds"> CSS</span>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                            <div className="col-md-12">
                                <hr className="border border-2" style={{ borderColor: "rgb(230, 235, 241)" }} />
                            </div>
                            <div className="col-md-12">
                                <div className="edit-icon new-card-header mb-4 mt-1" title="Assigned Programmes & Pages">
                                    <h4 className="card-header">
                                        Assigned Programmes & Pages
                                    </h4>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <PopupAssignedProgrammes
                                    id={id}
                                    assignedRec={assignedRec}
                                    setAssignedRec={setAssignedRec}
                                    assignloading={assignloading}
                                    setAssignLoading={setAssignLoading}
                                    refresh={refresh}
                                />
                            </div>

                        </div>
                        <div className="form-group form-group-save-cancel mt-4">
                            <PermissionsGate scopes={[type == 'create' ? 'wbspopuppageadd' : 'wbspopuppageedit']} RenderError={() => (
                                <button
                                    className="btn btn-save btn-success"
                                    type="submit"
                                    title="Save"
                                    disabled
                                >
                                    <i className="fal fa-save"></i>
                                    Save
                                </button>
                            )}>

                                <button
                                    className="btn btn-save btn-success"
                                    type="submit"
                                    title="Save"
                                    disabled={loading}
                                >
                                    {loading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                                    Save
                                </button>
                            </PermissionsGate>

                            <button
                                className="btn btn-close btn-danger"
                                type="button"
                                title="Cancel"
                                onClick={() => {
                                    if (type === "open" || type === "update") {
                                        formik.resetForm();
                                        setRefresh(Date.now());
                                    } else {
                                        history.push("/website_management/pages/popupspages/table");
                                    }
                                }}
                            >
                                <i className="fal fa-times"></i>
                                Cancel
                            </button>
                        </div>
                        {Object.keys(formik.errors).map((key) => {
                            if (formik.touched[key]) {
                                return (
                                    <div className="invalid-feedback d-block" key={key}>
                                        {formik.errors[key]}
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default PopupsPagesOpt;
