import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import PermissionsGate from "../../../utils/permissionGate";
import hasPermission from "../../../utils/hasMultiplePermission";
import Tablefilter from "../../common/Tablefilter";
import { RenderMenuItem, RenderMenuItemStatus, RendermenuitemStatus,  RenderMenuItemType,  RenderMenusType } from "../../../utils/CommonStatusItems";
import axios from "axios";
import { handleTableScroll } from "../../../utils/commonFunction";
import $ from "jquery";
import { DeleteMenuItemsList, GetMenusItemListsFilterCancelToken, getMenusItemsList } from "../../../services/Menusserver";
import CreateMenuItems from "./CreateMenuItems";
import UpdateMenuItems from "./UpdateMenuItemsMenu";
import UpdateMenuItemsMenu from "./UpdateMenuItemsMenu";
import { Modal } from 'react-bootstrap';


function MenuItem({ id }) {

    const history = useHistory();
    const [search, setSearch] = useState("");
    const [debouncedTerm, setDebouncedTerm] = useState("");
    const [status, setStatus] = useState({ arr: [], checkObj: {} });
    const [menuType, setMenuType] = useState({ arr: [], checkObj: {} });
    const [assignedSites, setAssignedSites] = useState({ arr: [], checkObj: {} });
    const [searchStatusCheck, setSearchStatusCheck] = useState({});
    const givenPermsisions = useSelector((state) => state.givenPermission);
    const [deleterow, setDeleteRow] = useState(false);
    const [menuData, setMenuData] = useState([]);
    const [loading, setloading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [updateData, setUpdateData] = useState(true);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [sortkey, setSortKey] = useState("MenuItemAlias");
    const [sortOrder, setSortOrder] = useState("desc");
    const [filterData, setFilterData] = useState({ status: [], menuType: [] });
    const [MenuItemID, setMenuItemID] = useState("")
    const [refresh, setRefresh] = useState(Date.now())
    const [menuItemModal, setMenuItemModal] = useState(false);
    const [updateMenuItemModal, setUpdateMenuItemModal] = useState(false);



    useEffect(() => {
        handleTableScroll()
    }, [loading])

    useEffect(() => {
        $(document).ready(function () {
            $(".dropdown-toggle").click(function () {
                $('.rdt_TableCell').css('z-index', 0)
                $(this).parents('.rdt_TableCell').css('z-index', 22)
            });
        });
    })

    useEffect(() => {
        const storedFilters = JSON.parse(localStorage.getItem("filterStateMenuItemListMain"));
        if (storedFilters) {
            setStatus(storedFilters.status);
            setMenuType(storedFilters.menuType);
        }
    }, []);

    useEffect(() => {
        const filterState = {
            status,
            menuType
        };
        localStorage.setItem("filterStateMenuItemListMain", JSON.stringify(filterState));
    }, [status, menuType]);

    useEffect(() => {
        $(document).ready(function () {
            $(".dropdown-toggle").click(function () {
                $('.rdt_TableCell').css('z-index', 0)
                $(this).parents('.rdt_TableCell').css('z-index', 22)
            });
        });
    })

    // get list
    useEffect(() => {
        const getMenuItemList = async () => {
            setloading(true);

            const menuItemData = {
                menu_id: id,
                isAssigned: 1,
                page: page,
                perPage: perPage,
                sort: sortOrder,
                key: sortkey,
                search: search,
                viaStatus: status.arr,
                viaType: menuType.arr,
            };

            try {
                const res = await getMenusItemsList(menuItemData);
                setMenuData(res?.data?.data);
                setTotalRows(res.data?.total);
                setPerPage(res.data?.perPage);
                if (res.status == 200) {
                    setloading(false);
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                    setloading(false);
                }
            }
        }

        getMenuItemList();
    }, [page, perPage, sortOrder, sortkey, search, status, menuType, refresh, searchStatusCheck, updateData]);

    // get dropdownlist
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await GetMenusItemListsFilterCancelToken(id, 1);
                setFilterData({
                    status: res?.data?.menusItemStatusFilters,
                    menuType: res?.data?.menusItemTypesFilters,
                })
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                }
            }
        };

        fetchData();
    }, [refresh]);

    useEffect(() => {
        let response = hasPermission({
            scopes: ["sview"],
            permissions: givenPermsisions,
        });
        if (!response) {
            history.push("/noaccess");
        }
    }, [deleterow]);

    const handleSearchFilter = (e) => {
        const value = e.target.value;
        setDebouncedTerm(value);
    };

    const resetFilter = () => {
        setStatus({ arr: [], checkObj: {} });
        setMenuType({ arr: [], checkObj: {} });
        setAssignedSites({ arr: [], checkObj: {} });
        setSearchStatusCheck({});
        setSearch("");
        setDebouncedTerm("");
    };

    useEffect(() => {
        return resetFilter();
    }, [])

    const handlePageChange = (pageNo) => {
        setPage(pageNo);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        setPerPage(newPerPage);
    };

    const handleSort = (column, sortDirection) => {
        setSortKey(column.sortField);
        setSortOrder(sortDirection === 'asc' ? 'asc' : 'desc');
    };

    const handleRedirection = (ID) => {
        if (hasPermission({ scopes: ["sgiview"], permissions: givenPermsisions })) {
            return `/studentAdministration/students/open/${ID}/general`;
        } else if (
            hasPermission({ scopes: ["spiview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/personal`;
        } else if (
            hasPermission({ scopes: ["sedview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/education`;
        } else if (
            hasPermission({ scopes: ["sapiview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/account`;
        } else if (
            hasPermission({ scopes: ["scpview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/coursesAndProgramme`;
        } else if (
            hasPermission({ scopes: ["sasview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/assessments`;
        } else if (
            hasPermission({ scopes: ["snview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/notifications`;
        } else if (
            hasPermission({ scopes: ["scaview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/classAttendance`;
        } else if (
            hasPermission({ scopes: ["slview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/licenses`;
        } else if (
            hasPermission({ scopes: ["scview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/certificates`;
        } else if (
            hasPermission({ scopes: ["saview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/appeals`;
        } else if (
            hasPermission({ scopes: ["stview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/tickets`;
        } else if (
            hasPermission({ scopes: ["snoview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/notes`;
        } else {
            return `/studentAdministration/students/open/${ID}/auditTrail/employee`;
        }
        // history.push(redirectLink)
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteMenuItemsList(id)
                    .then((res) => {
                        Swal.fire("Deleted!", "Menu items has been deleted.", "success");
                        setUpdateData(!updateData);
                        setRefresh(Date.now())
                    })
                    .catch((error) => console.log(error));
            }
        });
    }

    const columns = useMemo(() => [
        {
            name: "Title",
            selector: "MenuItemAlias",
            sortField: "MenuItemAlias",
            sortable: true,
            cell: (row) => (
                <div>
                    <span
                        className="as-text-blue curser feature-name"
                        onClick={() => {
                            setMenuItemID(row.MenuItemID)
                            setUpdateMenuItemModal(true)
                        }}
                        title={row.MenuItemLabel}
                        disabled={loading ? true : false}
                        data-toggle="modal"
                        data-target="#updatecreatemenuitem"
                    >
                        <span className="textLimit100">{row.MenuItemAlias}</span>
                    </span>
                </div>
            ),
        },
        // {
        //     name: "Menu Item Type",
        //     selector: "MenuItemType",
        //     sortField: "MenuItemType",
        //     sortable: true,
        //     cell: (row) => (
        //         row.MenuItemType ?
        //             <div className="textLimit100 item-text-green" title={row.MenuItemType}>
        //                 {row.MenuItemType}
        //             </div>
        //             : "NA"
        //     ),
        // },
        {
            name: "Menu Item Type",
            selector: "MenuItemType",
            sortField: "MenuItemType",
            sortable: true,
            cell: (row) => (
                row.MenuItemType ?
                    <div className="textLimit100 item-text-green" title={row?.MenuItemType}>
                        {RenderMenuItemType(row?.MenuItemType)?.html}
                    </div>
                    : "NA"
            ),
        },
        {
            name: "Status",
            selector: "MenuItemStatus",
            sortField: "MenuItemStatus",
            sortable: true,
            cell: (row) => RendermenuitemStatus(row.MenuItemStatus).html,
        },
        {
            name: "Actions",
            selector: "",
            cell: (row) => (
                <div className="assessment-08 btn-dropdown-grp">
                    <div className="as-buttons d-flex">
                        <button
                            className="btn btn-primary h-auto rounded-circle"
                            title="Open"
                            disabled={loading ? true : false}
                            data-toggle="modal"
                            data-target="#updatecreatemenuitem"
                            onClick={() => {
                                setUpdateMenuItemModal(true)
                                setMenuItemID(row.MenuItemID)
                            }}
                        >
                            <i className="fal fa-folder-open"></i>
                        </button>

                        <button
                            className="btn btn-danger rounded-circle"
                            onClick={() => handleDelete(row.MenuItemID)}
                            title="Delete"
                        >
                            <i className="fal fa-trash-alt"></i>
                        </button>
                    </div>
                </div>
            ),
        },
    ]);


    // TODO Excel
    // TODO Csv
    // TODO Pdf

    const exportData = (fileType, fileName) => {
        let data = [];
        const header = ["Title", "Menu Item Type", "Status"];
        Swal.fire({
            title: "File downloading",
            onOpen: function () {
                Swal.showLoading();
            },
        });

        const menuItemData = {
            exportStatus: "true",
            menu_id: Number(id),
            isAssigned: 1,
            sort: sortOrder,
            key: sortkey,
            search: search,
            viaStatus: status.arr,
            viaType: menuType.arr,
        };

        getMenusItemsList(menuItemData)
            .then((res) => {
                data = res?.data;
                data = data?.map((row) => {
                    return ({
                        ...row,
                        "Title": row?.MenuItemAlias,
                        "Menu Item Type": row.MenuItemType,
                        Status: row?.MenuItemStatus === 1 ? "ACTIVE" : "IN ACTIVE",
                    })
                });

                if (fileType === "csv") {
                    const csvString = Papa.unparse({ fields: header, data });
                    const blob = new Blob([csvString], {
                        type: "text/csv;charset=utf-8,",
                    });

                    const blobURL = window.URL.createObjectURL(blob);

                    // Create new tag for download file
                    const anchor = document.createElement("a");
                    anchor.download = fileName;
                    anchor.href = blobURL;
                    anchor.dataset.downloadurl = [
                        "text/csv",
                        anchor.download,
                        anchor.href,
                    ].join(":");
                    anchor.click();

                    // Remove URL.createObjectURL. The browser should not save the reference to the file.
                    setTimeout(() => {
                        // For Firefox it is necessary to delay revoking the ObjectURL
                        URL.revokeObjectURL(blobURL);
                    }, 1000);
                    Swal.close();
                } else if (fileType === "xlsx") {
                    const compatibleData = data.map((row) => {
                        const obj = {};
                        header.map((col, index) => {
                            obj[col] = row[col];
                        });
                        return obj;
                    });

                    let wb = XLSX.utils.book_new();
                    let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                        header,
                    });
                    XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
                    XLSX.writeFile(wb, `${fileName}.xlsx`);
                    Swal.close();
                    // Returning false as downloading of file is already taken care of
                    return false;
                }
                if (fileType === "pdf") {
                    const compatibleData = data?.map((row) => {
                        return [
                            row.MenuItemAlias,
                            row.MenuItemType,
                            row.MenuItemStatus === 1 ? "ACTIVE" : "IN ACTIVE",
                        ];
                    });
                    const doc = new JsPDF();
                    doc.autoTable({
                        head: [header],
                        body: compatibleData,
                        styles: {
                            minCellHeight: 10,
                            minCellWidth: 5,
                            halign: "left",
                            // valign: "center",
                            fontSize: 8,
                        },
                    });
                    doc.save(`${fileName}.pdf`);
                    Swal.close();
                    return false;
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
        return () => {
            clearTimeout(timer);
        }
    }, [debouncedTerm])

    const closeModal = () => {
        setMenuItemModal(false);
    };

    return (
        <>
            <div className="my-tickets-info-list Tickets-main-wrap">
                <div className="custom-table-div filter-search-icon card card-table-custom program-table">
                    <div className="search-filter-div">
                        <div className="search-filter-div-left">
                            <div className="system-administration-table table-responsive">
                                <div className="table-responsive-div">
                                    <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                                        <div id="assessment-table-main_filter" className="dataTables_filter">
                                            <label>
                                                <input
                                                    type="search"
                                                    className=""
                                                    placeholder="Search"
                                                    aria-controls="assessment-table-main"
                                                    onChange={handleSearchFilter}
                                                    value={debouncedTerm}
                                                />
                                            </label>
                                            <div className="filter-eff filter-data-btn">
                                                <button className="filter-buttons">
                                                    <i className="fal fa-filter"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-button-group">
                                    <div className="filter-scroll">
                                        <div className={`filter-scroll-inner filter-custom-new`}>
                                            <Tablefilter
                                                filterName="Status"
                                                optionArr={filterData?.status}
                                                state={status}
                                                setState={setStatus}
                                                stopOptionSorting={true}
                                                isOptionReversed={false}
                                                renderLabelFunction={RenderMenuItemStatus}
                                            />
                                        </div>
                                    </div>
                                    <div className="filter-scroll">
                                        <div className={`filter-scroll-inner filter-custom-new`}>
                                            <Tablefilter
                                                filterName="Menu Item Type"
                                                optionArr={filterData?.menuType}
                                                state={menuType}
                                                setState={setMenuType}
                                                stopOptionSorting={true}
                                                isOptionReversed={false}
                                                renderLabelFunction={RenderMenuItemType}
                                            />
                                        </div>
                                    </div>
                                    <div className="reset-btn-group">
                                        <div className="button-reset dropdown-comman">
                                            <button
                                                className="btn btn-primary"
                                                onClick={resetFilter}
                                                title="Reset"
                                            >
                                                <i className="fal fa-redo"></i>Reset
                                            </button>
                                        </div>
                                        <div className="files-export-group">
                                            <button
                                                type="button"
                                                className="btn btn-files"
                                                onClick={() => {
                                                    exportData("xlsx", "Menu Items");
                                                }}
                                                title="Export spreadsheet"
                                            >
                                                <i className="fal fa-file-excel icon"></i>
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-files"
                                                onClick={() => {
                                                    exportData("csv", "Menu Items");
                                                }}
                                                title="Export CSV"
                                            >
                                                <i className="fal fa-file-csv icon"></i>
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-files"
                                                onClick={() => {
                                                    exportData("pdf", "Menu Items");
                                                }}
                                                title="Export PDF"
                                            >
                                                <i className="fal fa-file-pdf icon"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="search-filter-div-right">
                            <div className=" filter-search-bar-blk">
                                <div className="add-ticket-blk button-reset dropdown-comman">
                                    <PermissionsGate
                                        RenderError={() => (
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                title="Create new"
                                                disabled
                                            >
                                                <i className="fal fa-plus"></i>Create New
                                            </button>
                                        )}
                                        scopes={["sadd"]}
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            title="Create new"
                                            disabled={loading ? true : false}
                                            data-toggle="modal"
                                            data-target="#createmenuitem"
                                            onClick={() => setMenuItemModal(true)}
                                        >
                                            <i className="fal fa-plus"></i>Create New
                                        </button>
                                    </PermissionsGate>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DataTable
                        paginationDefaultPage={page}
                        progressPending={loading}
                        data={menuData}
                        progressComponent={<SkeletonTicketList />}
                        defaultSortField={sortkey}
                        defaultSortAsc={false}
                        columns={columns}
                        pagination={true}
                        // noDataComponent={Str.noRecord}
                        onSort={handleSort}
                        sortServer
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        highlightOnHover={false}
                        paginationPerPage={perPage}
                        paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                    />
                </div>
            </div>

            {/* <div
                className="topic-add-modal modal fade"
                id="updatecreatemenuitem"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content modal-border-update">
                        <div className="modal-body p-0">
                            <div className="modal-header modal-header-custom">
                                <h5 className="modal-title">
                                    <i className="fas fa-bars"></i> Update Menu Item
                                </h5>
                                <button type="button" data-dismiss="modal" className="close" title="Close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
                            </div>
                            <UpdateMenuItemsMenu page={"update"} Userid={MenuItemID} id={id} refresh={refresh} setRefresh={setRefresh} />
                        </div>
                    </div>
                </div>
            </div> */}
            <Modal
                show={updateMenuItemModal}
                onHide={() => setUpdateMenuItemModal(false)}
                size="xl"
                centered
                aria-labelledby="exampleModalCenterTitle"
            >
                <Modal.Header className="modal-header-custom" closeButton>
                    <Modal.Title>
                        <i className="fas fa-bars"></i> Update Menu Item
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className="p-0">
                    <UpdateMenuItemsMenu
                        page="update"
                        Userid={MenuItemID}
                        id={id}
                        refresh={refresh}
                        setRefresh={setRefresh}
                        updateMenuItemModal={updateMenuItemModal}
                        setUpdateMenuItemModal={setUpdateMenuItemModal}
                    />
                </Modal.Body>
            </Modal>


            <Modal show={menuItemModal} onHide={closeModal} centered size="xl">
                <div className="modal-content modal-border-update">
                    <div className="modal-body p-0">
                        <div className="modal-header modal-header-custom">
                            <h5 className="modal-title">
                                <i className="fas fa-bars"></i> Create Menu Item
                            </h5>
                            <button type="button" onClick={closeModal} className="close" title="Close">
                                <span aria-hidden="true">×</span>
                                <span className="sr-only">Close</span>
                            </button>
                        </div>
                        <CreateMenuItems page={"create"} menuItemModal={menuItemModal} closeModal={closeModal} id={id} refresh={refresh} setRefresh={setRefresh} />
                    </div>
                </div>
            </Modal>


            {/* <div
                className="topic-add-modal modal fade"
                id="createmenuitem"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content modal-border-update">
                        <div className="modal-body p-0">
                            <div className="modal-header modal-header-custom">
                                <h5 className="modal-title">
                                    <i className="fas fa-bars"></i> Create Menu Item
                                </h5>
                                <button type="button" data-dismiss="modal" className="close" title="Close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
                            </div>
                            <CreateMenuItems page={"create"} id={id} refresh={refresh} setRefresh={setRefresh} />
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default MenuItem;
